import React from 'react';
import { Link } from 'react-router-dom';
import { FaExclamationTriangle } from 'react-icons/fa';

const ErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-gray-800">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full border border-gray-200">
        <FaExclamationTriangle className="text-red-500 text-6xl mb-4 animate-pulse" />
        <h1 className="text-4xl font-extrabold mb-2 text-gray-900">Oops! Something Went Wrong</h1>
        <p className="text-lg mb-6 text-gray-600">
          The page you’re looking for doesn’t exist or an error occurred.
        </p>
        <Link 
          to="/" 
          className="bg-blue-600 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-300"
          aria-label="Go to Home"
        >
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
