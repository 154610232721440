import React, { useState } from 'react';
import { FaShareAlt, FaFacebook, FaTwitter, FaLinkedin, FaCopy, FaCheck, FaTimes } from 'react-icons/fa';

const ShareButtons = ({ url }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setLinkCopied(false);
  };

  const shareToFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(facebookUrl, '_blank');
  };

  const shareToTwitter = () => {
    const twitterUrl = `https://twitter.com/share?url=${encodeURIComponent(url)}`;
    window.open(twitterUrl, '_blank');
  };

  const shareToLinkedIn = () => {
    const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`;
    window.open(linkedInUrl, '_blank');
  };

  const copyLink = () => {
    navigator.clipboard.writeText(url).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 2000);
    });
  };

  return (
    <div className="relative">
      <button
        onClick={togglePopup}
        className="bg-indigo-500 text-white py-2 px-4 rounded-lg hover:bg-indigo-600 transition flex items-center space-x-2"
      >
        <FaShareAlt className="w-5 h-5" />
        <span>Share</span>
      </button>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white border border-gray-300 shadow-lg rounded-lg p-6 w-64 text-center relative">
            <button
              onClick={togglePopup}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 transition"
            >
              <FaTimes className="w-4 h-4" />
            </button>
            <h3 className="text-lg font-semibold mb-4">Share this post</h3>
            <div className="flex flex-col space-y-3">
              <button
                onClick={copyLink}
                className="flex items-center justify-center space-x-2 bg-gray-100 hover:bg-gray-200 py-2 px-3 rounded-lg transition"
              >
                {linkCopied ? (
                  <>
                    <FaCheck className="w-4 h-4 text-green-500" />
                    <span>Copied</span>
                  </>
                ) : (
                  <>
                    <FaCopy className="w-4 h-4" />
                    <span>Copy Link</span>
                  </>
                )}
              </button>
              <button
                onClick={shareToFacebook}
                className="flex items-center justify-center space-x-2 bg-blue-600 hover:bg-blue-700 text-white py-2 px-3 rounded-lg transition"
              >
                <FaFacebook className="w-4 h-4" />
                <span>Facebook</span>
              </button>
              <button
                onClick={shareToTwitter}
                className="flex items-center justify-center space-x-2 bg-blue-400 hover:bg-blue-500 text-white py-2 px-3 rounded-lg transition"
              >
                <FaTwitter className="w-4 h-4" />
                <span>Twitter</span>
              </button>
              <button
                onClick={shareToLinkedIn}
                className="flex items-center justify-center space-x-2 bg-blue-700 hover:bg-blue-800 text-white py-2 px-3 rounded-lg transition"
              >
                <FaLinkedin className="w-4 h-4" />
                <span>LinkedIn</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareButtons;
