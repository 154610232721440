import React, { useState } from 'react';
import { Button, Input, Upload, DatePicker, Select, Modal, notification, Tag, Switch, Radio } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import "antd/dist/reset.css";
import "tailwindcss/tailwind.css";

const { Option } = Select;
const { TextArea } = Input;

const PostTutorialForm = () => {
  // State hooks
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [projectDate, setProjectDate] = useState(null);
  const [isPublished, setIsPublished] = useState(true);
  const [visibility, setVisibility] = useState('public');
  const [difficulty, setDifficulty] = useState('easy');
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Handler functions
  const handleFileUpload = (info) => {
    if (info.file.status === 'done') {
      const url = URL.createObjectURL(info.file.originFileObj);
      setFileUrls([...fileUrls, url]);
      setFiles([...files, info.file.originFileObj]);
    } else if (info.file.status === 'uploading') {
      // Optional: Display upload progress or status
    }
  };

  const handleTagInput = (tag) => {
    if (tag && !tags.includes(tag)) {
      setTags([...tags, tag]);
    }
  };

  const handleTagClose = (tag) => {
    setTags(tags.filter(t => t !== tag));
  };

  const handleSubmit = async () => {
    if (!title || !description || !category) {
      notification.error({
        message: 'Form Error',
        description: 'Please fill out all required fields.',
      });
      return;
    }

    setIsLoading(true);

    // Submit tutorial data logic
    const tutorial = {
      title,
      description,
      category,
      tags,
      files,
      projectDate,
      isPublished,
      visibility,
      difficulty,
    };

    try {
      // Simulating API call
      console.log('Submitting tutorial:', tutorial);
      notification.success({
        message: 'Success',
        description: 'Tutorial posted successfully!',
      });
      resetForm();
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an issue submitting your tutorial.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setCategory('');
    setTags([]);
    setFiles([]);
    setFileUrls([]);
    setProjectDate(null);
    setIsPublished(true);
    setVisibility('public');
    setDifficulty('easy');
  };

  const confirmExit = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: 'You have unsaved changes!',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to leave without saving your changes?',
      onOk: () => {
        window.location.href = '/';
      },
    });
  };

  const togglePreview = () => {
    if (!title || !description) {
      notification.warning({
        message: 'Preview Error',
        description: 'Please enter a title and description before previewing.',
      });
      return;
    }
    setShowPreview(!showPreview);
  };

  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-lg max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Post a New Tutorial</h2>
      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} onReset={resetForm}>
        {/* Title Input */}
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Tutorial Title"
          required
          className="mb-4 border border-gray-300 rounded-lg px-4 py-2 w-full"
        />
        
        {/* Description Input */}
        <TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Tutorial Description"
          required
          className="mb-4 border border-gray-300 rounded-lg px-4 py-2 w-full"
        />
        
        {/* Category Selector */}
        <Select
          value={category}
          onChange={setCategory}
          placeholder="Select Category"
          required
          className="mb-4 w-full"
        >
          <Option value="web-development">Web Development</Option>
          <Option value="mobile-app">Mobile App</Option>
          <Option value="data-science">Data Science</Option>
          <Option value="design">Design</Option>
          <Option value="other">Other</Option>
        </Select>
        
        {/* Tags Input */}
        <Input
          placeholder="Enter tags and press Enter"
          onPressEnter={(e) => {
            handleTagInput(e.target.value);
            e.target.value = "";
          }}
          className="mb-4 border border-gray-300 rounded-lg px-4 py-2 w-full"
        />
        <div className="mb-4">
          {tags.map((tag, index) => (
            <Tag
              key={index}
              closable
              onClose={() => handleTagClose(tag)}
            >
              {tag}
            </Tag>
          ))}
        </div>
        
        {/* File Upload */}
        <Upload
          name="files"
          multiple
          showUploadList={false}
          beforeUpload={() => false}
          onChange={handleFileUpload}
        >
          <Button icon={<PlusOutlined />} className="mb-4">
            Upload Tutorial Files
          </Button>
        </Upload>
        
        {/* File Download Links */}
        <div className="mb-4">
          {fileUrls.map((url, index) => (
            <div key={index} className="flex items-center mb-2">
              <a href={url} download={`file${index + 1}`} className="mr-2">
                <Button icon={<DownloadOutlined />} size="small">Download</Button>
              </a>
              <span>File {index + 1}</span>
            </div>
          ))}
        </div>
        
        {/* Project Date */}
        <DatePicker
          value={projectDate}
          onChange={setProjectDate}
          placeholder="Select Project Date"
          className="mb-4 w-full"
        />
        
        {/* Published Switch */}
        <div className="mb-4">
          <span className="mr-2">Published:</span>
          <Switch
            checked={isPublished}
            onChange={setIsPublished}
          />
        </div>
        
        {/* Visibility Selector */}
        <Select
          value={visibility}
          onChange={setVisibility}
          placeholder="Select Visibility"
          className="mb-4 w-full"
        >
          <Option value="public">Public</Option>
          <Option value="private">Private</Option>
        </Select>
        
        {/* Difficulty Level Selector */}
        <div className="mb-4">
          <span className="block mb-2">Difficulty Level:</span>
          <Radio.Group
            value={difficulty}
            onChange={(e) => setDifficulty(e.target.value)}
          >
            <Radio value="easy">Easy</Radio>
            <Radio value="medium">Medium</Radio>
            <Radio value="hard">Hard</Radio>
          </Radio.Group>
        </div>
        
        {/* Buttons */}
        <div className="flex justify-between items-center">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="mr-4"
          >
            Submit
          </Button>
          <Button htmlType="reset" onClick={resetForm} className="mr-4">
            Reset
          </Button>
          <Button onClick={togglePreview}>
            {showPreview ? "Hide Preview" : "Show Preview"}
          </Button>
          <Button type="default" danger onClick={confirmExit}>
            Exit Without Saving
          </Button>
        </div>
      </form>
      
      {/* Preview Modal */}
      {showPreview && (
        <Modal
          visible={showPreview}
          title="Tutorial Preview"
          onCancel={togglePreview}
          footer={[
            <Button key="back" onClick={togglePreview}>
              Close
            </Button>,
          ]}
        >
          <h2>{title}</h2>
          <div><strong>Description:</strong> {description}</div>
          <div><strong>Category:</strong> {category}</div>
          <div><strong>Tags:</strong> {tags.join(', ')}</div>
          <div><strong>Project Date:</strong> {projectDate ? projectDate.format('YYYY-MM-DD') : 'Not set'}</div>
          <div><strong>Published:</strong> {isPublished ? 'Yes' : 'No'}</div>
          <div><strong>Visibility:</strong> {visibility}</div>
          <div><strong>Difficulty Level:</strong> {difficulty}</div>
          <div>
            <strong>Files:</strong>
            {fileUrls.map((url, index) => (
              <div key={index} className="flex items-center mt-2">
                <a href={url} download={`file${index + 1}`} className="mr-2">
                  <Button icon={<DownloadOutlined />} size="small">Download</Button>
                </a>
                <span>File {index + 1}</span>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PostTutorialForm;
