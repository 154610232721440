const conf = {
    appwriteUrl: String(process.env.REACT_APP_APPWRITE_ENDPOINT),
    appwriteProjectId: String(process.env.REACT_APP_APPWRITE_PROJECT_ID),
    appwriteDatabaseId: String(process.env.REACT_APP_APPWRITE_DATABASE_ID),
    appwriteBlogCollectionId: String(process.env.REACT_APP_APPWRITE_BLOGCOLLECTION_ID),
    appwriteCommentsCollectionId: String(process.env.REACT_APP_APPWRITE_COMMENTCOLLECTION_ID),
    appwriteProjectCollectionId: String(process.env.REACT_APP_APPWRITE_PROJECTSCOLLECTION_ID),
    appwriteTutorialCollectionId: String(process.env.REACT_APP_APPWRITE_TUTORIALCOLLECTION_ID),
    appwriteJobsCollectionId: String(process.env.REACT_APP_APPWRITE_JOBSCOLLECTION_ID),
    appwriteBucketId: String(process.env.REACT_APP_APPWRITE_BUCKET_ID),
    appwriteSecretKey: String(process.env.REACT_APP_APPWRITE_SECRET_KEY),
}

export default conf
