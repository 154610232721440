import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const HeroBanner = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [greeting, setGreeting] = useState('');
  const [fadeOut, setFadeOut] = useState(false); // State for fade-out animation

  const navigate = useNavigate(); // Initialize useNavigate

  // Dynamic greeting based on time of day
  useEffect(() => {
    const hour = new Date().getHours();
    if (hour < 12) setGreeting('Good Morning');
    else if (hour < 18) setGreeting('Good Afternoon');
    else setGreeting('Good Evening');
  }, []);

  // Debounced function to handle search suggestions
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fetchSuggestions = debounce((term) => {
    const mockSuggestions = ['React', 'JavaScript', 'CSS', 'Tailwind', 'Node.js'].filter((item) =>
      item.toLowerCase().includes(term.toLowerCase())
    );
    setSuggestions(mockSuggestions);
  }, 300); // Debounce delay set to 300ms

  // Handle search input change
  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.length > 1) fetchSuggestions(term);
    else setSuggestions([]);
  };

  // Handle search functionality with animation and redirection
  const handleSearch = () => {
    if (!searchTerm.trim()) return;

    // Start fade-out animation
    setFadeOut(true);

    setTimeout(() => {
      // Redirect to search results page after animation
      navigate(`/search?query=${searchTerm}`);
    }, 300); // Match this delay with the fade-out duration
  };

  // Trigger search on pressing Enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleSearch();
  };

  // Handle clearing the search input
  const clearSearch = () => {
    setSearchTerm('');
    setSuggestions([]);
  };

  // Handle deleting a specific recent search
  const deleteRecentSearch = (term) => {
    setRecentSearches((prev) => prev.filter((item) => item !== term));
  };

  return (
    <div
      className={`flex flex-col items-center justify-center text-center py-12 px-6 transition-all duration-300 ease-in-out ${
        fadeOut ? 'opacity-0' : 'opacity-100'
      }`}
    >
      {/* Dynamic Greeting */}
      <h1 className="text-4xl font-bold mb-4 animate-fadeIn">{greeting}, Welcome to TheCampusCoders!</h1>
      <p className="text-lg mb-8">Search for resources, projects, and more.</p>

      {/* Search Bar with Suggestions */}
      <div className="relative w-full max-w-md">
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Start typing to search..."
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="w-full px-4 py-3 rounded-full shadow-md focus:outline-none transition-all"
            aria-label="Search input"
          />
          {searchTerm && (
            <button
              onClick={clearSearch}
              className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
              aria-label="Clear search"
            >
              ✖️
            </button>
          )}
        </div>
        {/* Autocomplete Suggestions */}
        {suggestions.length > 0 && (
          <ul className="absolute w-full bg-white border mt-2 rounded-lg shadow-lg z-10 transition-all duration-200 ease-in-out">
            {suggestions.map((item, index) => (
              <li
                key={index}
                className="p-2 hover:bg-gray-200 cursor-pointer"
                onClick={() => {
                  setSearchTerm(item);
                  handleSearch(); // Trigger search on clicking a suggestion
                }}
              >
                <span>
                  {item.slice(0, item.toLowerCase().indexOf(searchTerm.toLowerCase()))}
                  <strong className="text-blue-600">
                    {item.slice(
                      item.toLowerCase().indexOf(searchTerm.toLowerCase()),
                      item.toLowerCase().indexOf(searchTerm.toLowerCase()) + searchTerm.length
                    )}
                  </strong>
                  {item.slice(item.toLowerCase().indexOf(searchTerm.toLowerCase()) + searchTerm.length)}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Recent Searches */}
      {recentSearches.length > 0 && (
        <div className="mt-6">
          <h3 className="text-xl font-semibold mb-2">Recent Searches</h3>
          <ul className="flex gap-2 flex-wrap">
            {recentSearches.map((item, index) => (
              <li
                key={index}
                className="bg-gray-200 px-4 py-2 rounded-full cursor-pointer hover:bg-gray-300 relative"
                onClick={() => {
                  setSearchTerm(item);
                  handleSearch(); // Trigger search on clicking a recent search
                }}
              >
                {item}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteRecentSearch(item);
                  }}
                  className="absolute top-0 right-0 -mt-2 -mr-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  aria-label={`Delete ${item}`}
                >
                  ✖️
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default HeroBanner;
