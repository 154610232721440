import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FaLink } from 'react-icons/fa';

const resources = [
  {
    title: 'React Documentation',
    description: 'Official documentation for React.js, including guides and API reference.',
    link: 'https://reactjs.org/',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYFYUMxwjoJUgk-Bv9mwUGhi6uhAIKOfWZHw&s',
  },
  {
    title: 'JavaScript Info',
    description: 'Comprehensive guide and tutorials for modern JavaScript.',
    link: 'https://javascript.info/',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtME2Ho74uhChIuase5oqeJujVV-wmBEAWAg&s',
  },
  {
    title: 'CSS Tricks',
    description: 'Tips, tricks, and techniques on CSS for better styling and layout.',
    link: 'https://css-tricks.com/',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT54tQftL5rbj7BQAUSBnYO395UlkMX0PRcvQ&s',
  },
  {
    title: 'Node.js Guide',
    description: 'Official Node.js documentation with tutorials and API reference.',
    link: 'https://nodejs.org/en/docs/',
    image: 'https://media.geeksforgeeks.org/wp-content/uploads/20230331172641/NodeJS-copy.webp',
  },
  {
    title: 'MDN Web Docs',
    description: 'Mozilla Developer Network’s resources for HTML, CSS, and JavaScript.',
    link: 'https://developer.mozilla.org/',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZHySSqnhheCFk05NEyYEu5r0X2Fl14HWLog&s',
  },
];

const UsefulResourceSection = () => {
  return (
    <section className="py-10">
      <div className="container">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Useful Resources</h2>

        <Swiper
          spaceBetween={20}
          slidesPerView={3.5}
          breakpoints={{
            320: {
              slidesPerView: 1.1,
            },
            640: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3.5,
            },
          }}
        >
          {resources.map((resource, index) => (
            <SwiperSlide key={index}>
              <a href={resource.link} target="_blank" rel="noopener noreferrer" className="block bg-white shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <img src={resource.image} alt={resource.title} className="w-full h-48 object-cover" />
                <div className="p-5">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">{resource.title}</h3>
                  <p className="text-gray-600 mb-4">{resource.description}</p>
                  <div className="flex items-center text-blue-500 text-sm">
                    <FaLink className="mr-2" />
                    Learn More
                  </div>
                </div>
              </a>
            </SwiperSlide>
          ))}

          {/* Button at the End */}
          <SwiperSlide>
            <div className="flex justify-center items-center h-full">
              <button
                onClick={() => window.location.href = '/all-resources'}
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 px-8 rounded-full hover:bg-gradient-to-r hover:from-purple-500 hover:to-blue-500 transition-all duration-300 shadow-lg"
              >
                See All Resources
              </button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default UsefulResourceSection;
