import React, { useState, useEffect } from 'react';

// Mock function to simulate fetching search results
const fetchSearchResults = (query, filters) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const results = {
        blogs: [{ title: 'React Basics', link: '/blog/react-basics' }],
        projects: [{ title: 'Portfolio Website', link: '/projects/portfolio' }],
        jobs: [{ title: 'Frontend Developer', link: '/jobs/frontend-developer' }],
        tutorials: [{ title: 'Learn JavaScript', link: '/tutorials/learn-javascript' }],
      };
      resolve(results);
    }, 1000);
  });
};

const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState('All');
  const [results, setResults] = useState({ blogs: [], projects: [], jobs: [], tutorials: [] });
  const [recentSearches, setRecentSearches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [popularSearches, setPopularSearches] = useState(['React', 'JavaScript', 'Web Development']);
  const [savedSearches, setSavedSearches] = useState([]);

  useEffect(() => {
    if (searchTerm.length > 2) {
      fetchSuggestions(searchTerm);
    } else {
      setSuggestions([]);
    }
  }, [searchTerm]);

  const fetchSuggestions = (term) => {
    const mockSuggestions = ['React', 'JavaScript', 'CSS', 'Node.js'].filter((item) =>
      item.toLowerCase().includes(term.toLowerCase())
    );
    setSuggestions(mockSuggestions);
  };

  const handleSearch = async () => {
    if (!searchTerm.trim()) return;
    setIsLoading(true);
    try {
      const fetchedResults = await fetchSearchResults(searchTerm, category);
      setResults(fetchedResults);
      setNoResults(!Object.values(fetchedResults).flat().length);
      setRecentSearches((prev) => [...new Set([searchTerm, ...prev])].slice(0, 5));
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleSearch();
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSuggestions([]);
  };

  const saveSearch = () => {
    if (searchTerm && !savedSearches.includes(searchTerm)) {
      setSavedSearches((prev) => [...prev, searchTerm]);
    }
  };

  const handleShare = () => {
    // Implement sharing functionality (e.g., open share dialog)
    alert('Sharing functionality is not yet implemented.');
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      {/* Hero Section */}
      <div className="text-center mb-12">
        <h1 className="text-5xl font-bold text-blue-800 mb-4">Discover Your Next Big Thing</h1>
        <p className="text-lg text-gray-600">Search through blogs, projects, jobs, and tutorials to find what you need.</p>
      </div>

      {/* Search Input */}
      <div className="relative flex items-center max-w-md mx-auto mb-6">
        <input
          type="text"
          placeholder={`Search ${category.toLowerCase()}...`}
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          className="w-full px-4 py-3 border rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {searchTerm && (
          <button
            onClick={clearSearch}
            className="absolute right-0 top-0 mt-2 mr-3 text-gray-500 hover:text-gray-700"
            aria-label="Clear search"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        )}
        {suggestions.length > 0 && (
          <ul className="absolute w-full bg-white border border-gray-300 mt-2 rounded-lg shadow-lg z-10">
            {suggestions.map((item, index) => (
              <li
                key={index}
                className="p-2 cursor-pointer hover:bg-gray-100"
                onClick={() => setSearchTerm(item)}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Search and Actions */}
      <div className="flex justify-center mb-8 space-x-4">
        <button
          onClick={handleSearch}
          className="px-6 py-3 bg-blue-600 text-white rounded-full shadow-md hover:bg-blue-700"
        >
          Search
        </button>
        {searchTerm && (
          <button
            onClick={saveSearch}
            className="px-6 py-3 bg-green-600 text-white rounded-full shadow-md hover:bg-green-700"
          >
            Save Search
          </button>
        )}
        <button
          onClick={handleShare}
          className="px-6 py-3 bg-purple-600 text-white rounded-full shadow-md hover:bg-purple-700"
        >
          Share Results
        </button>
      </div>

      {/* Category Filters */}
      <div className="flex justify-center mb-8 space-x-4">
        {['All', 'Blogs', 'Projects', 'Jobs', 'Tutorials'].map((cat) => (
          <button
            key={cat}
            onClick={() => handleCategoryChange(cat)}
            className={`px-4 py-2 rounded-full transition ${
              category === cat ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            {cat}
          </button>
        ))}
      </div>

      {/* Recent Searches */}
      {recentSearches.length > 0 && (
        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-blue-800">Recent Searches</h2>
          <ul className="space-y-2">
            {recentSearches.map((item, index) => (
              <li key={index} className="border-b pb-2 text-gray-700">{item}</li>
            ))}
          </ul>
        </div>
      )}

      {/* Search Results */}
      <div>
        {isLoading && <p className="text-center text-gray-500">Loading...</p>}
        {noResults && !isLoading && <p className="text-center text-gray-500">No results found.</p>}
        {!isLoading && !noResults && (
          <>
            {['blogs', 'projects', 'jobs', 'tutorials'].map((type) => (
              results[type].length > 0 && (
                <div key={type} className="mb-8">
                  <h2 className="text-2xl font-semibold mb-4 text-blue-800">{type.charAt(0).toUpperCase() + type.slice(1)}</h2>
                  <ul className="space-y-2">
                    {results[type].map((item, index) => (
                      <li key={index}>
                        <a href={item.link} className="text-blue-600 hover:underline">
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            ))}
          </>
        )}
      </div>

      {/* Popular Searches */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-blue-800">Popular Searches</h2>
        <div className="flex flex-wrap gap-3">
          {popularSearches.map((item, index) => (
            <span key={index} className="bg-gray-200 px-3 py-1 rounded-full text-gray-700 cursor-pointer hover:bg-gray-300">
              #{item}
            </span>
          ))}
        </div>
      </div>

      {/* Saved Searches */}
      {savedSearches.length > 0 && (
        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-blue-800">Saved Searches</h2>
          <ul className="space-y-2">
            {savedSearches.map((item, index) => (
              <li key={index} className="text-gray-700">{item}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchPage;

