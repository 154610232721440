import React, { useState, useEffect } from "react";
import { Header, Footer, Loading } from "./Components";
import { Outlet, useLocation } from "react-router-dom";
import AuthService from "./Appwrite/Auth";
import { useDispatch } from "react-redux";
import { login, logout } from "./Store/authSlice";

const App = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const hideHeaderFooterRoutes = ["/admin", "/dashboard"];
  const shouldHideHeaderFooter = hideHeaderFooterRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  useEffect(() => {
    const authService = new AuthService();
    authService
      .getCurrentUser()
      .then((userData) => {
        if (userData) {
          dispatch(login({ userData }));
        } else {
          dispatch(logout());
        }
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen flex flex-col justify-between bg-gray-100">
      {!shouldHideHeaderFooter && <Header />}
      <main className="flex-grow">
        <Outlet />
      </main>
      {!shouldHideHeaderFooter && <Footer />}
    </div>
  );
};

export default App;
