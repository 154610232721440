// Seo.js
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import metaDefaults from "./metaDefaults.json";

const Seo = ({ title, description, imageUrl, imageAlt, url, keywords, canonical }) => {
  const { pathname } = useLocation();
  const currentUrl = `${url}${pathname}`;
  const meta = {
    title: title || metaDefaults.title,
    description: description || metaDefaults.description,
    imageUrl: imageUrl || metaDefaults.imageUrl,
    imageAlt: imageAlt || metaDefaults.imageAlt,
    url: currentUrl,
    keywords: keywords || metaDefaults.keywords,
    canonical: canonical || currentUrl,
  };

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={meta.imageUrl} />
      <meta property="og:image:alt" content={meta.imageAlt} />
      <meta property="og:url" content={meta.url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <link rel="canonical" href={meta.canonical} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.imageUrl} />
      <meta name="twitter:image:alt" content={meta.imageAlt} />
      
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  url: PropTypes.string,
  keywords: PropTypes.string,
  canonical: PropTypes.string,
};

export default Seo;
