import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FaBriefcase, FaUserTie } from 'react-icons/fa';

const careerAdvice = [
  {
    title: 'Building a Strong Portfolio',
    description: 'Tips on creating a standout portfolio that showcases your skills and projects effectively.',
    link: '/portfolio-tips',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ4rmiN0MaFt5wWBjoSyJJaCmOW2Ma5lq-Vw&s',
  },
  {
    title: 'Networking Strategies for Success',
    description: 'Effective networking techniques to build valuable professional relationships and opportunities.',
    link: '/networking-strategies',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXyTfoq0RM7ANZKa-WnYCvbpukDCF01EFL8A&s',
  },
  {
    title: 'Resume Writing Best Practices',
    description: 'How to craft a resume that grabs attention and highlights your key accomplishments.',
    link: '/resume-writing',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGOBO_sDvxUKIamvSTzOpV2wIehNQHanIvZg&s',
  },
  {
    title: 'Interview Preparation Tips',
    description: 'Advice on preparing for interviews to confidently answer questions and leave a great impression.',
    link: '/interview-preparation',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRItSjDDGZdeW1xBz0GGrOjYESDw3Z3v53c4g&s',
  },
  {
    title: 'Navigating Career Changes',
    description: 'Guidance on making a smooth transition between roles or industries and adapting to new challenges.',
    link: '/career-changes',
    image: 'https://res-1.cloudinary.com/hifirsa5v/image/upload/q_auto/v1/ghost-blog-images/Blue-Fun-Competitive-Analysis-Brainstorm-Presentation.png',
  },
];

const CareerAdvice = () => {
  return (
    <section className="py-10">
      <div className="container">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Career Advice</h2>

        <Swiper
          spaceBetween={20}
          slidesPerView={3.5}
          breakpoints={{
            320: {
              slidesPerView: 1.1,
            },
            640: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3.5,
            },
          }}
        >
          {careerAdvice.map((advice, index) => (
            <SwiperSlide key={index}>
              <a href={advice.link} className="block bg-white shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <img src={advice.image} alt={advice.title} className="w-full h-48 object-cover" />
                <div className="p-5">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">{advice.title}</h3>
                  <p className="text-gray-600 mb-4">{advice.description}</p>
                  <div className="flex items-center text-blue-500 text-sm">
                    <FaBriefcase className="mr-2" />
                    Learn More
                  </div>
                </div>
              </a>
            </SwiperSlide>
          ))}

          {/* Button at the End */}
          <SwiperSlide>
            <div className="flex justify-center items-center h-full">
              <button
                onClick={() => window.location.href = '/all-career-advice'}
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 px-8 rounded-full hover:bg-gradient-to-r hover:from-purple-500 hover:to-blue-500 transition-all duration-300 shadow-lg"
              >
                See All Career Advice
              </button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default CareerAdvice;
