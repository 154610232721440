import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../../Contexts/AuthContext';



const ProtectedRoute = ({ allowedRoles = [] }) => {
  const { user } = useContext(AuthContext);
  
  if (!user) return <Navigate to="/auth/login" replace />;

  if (allowedRoles.length > 0 && !allowedRoles.some(role => user.labels?.includes(role))) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};


export default ProtectedRoute;
