import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FaPlayCircle, FaRegClock } from 'react-icons/fa';

const tutorials = [
  {
    title: 'Getting Started with React',
    description: 'A comprehensive guide for beginners to start building React applications.',
    duration: '45 min',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLGcoc8elsE40wC0KRSyFdXeg_tlk8KhjPdA&s',
    link: '/tutorials/getting-started-with-react',
  },
  {
    title: 'Understanding JavaScript Closures',
    description: 'Deep dive into closures and how they work in JavaScript.',
    duration: '30 min',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-mJVG1GtTW6Oj1EkyRxp9ntyum2k_qD6wvQ&s',
    link: '/tutorials/javascript-closures',
  },
  {
    title: 'CSS Grid Layout Mastery',
    description: 'Learn how to use CSS Grid to create complex layouts with ease.',
    duration: '60 min',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUGyiXfxusIJs6lrbcabkKwCCNH39k04X5RA&s',
    link: '/tutorials/css-grid-layout',
  },
  {
    title: 'Intro to Node.js',
    description: 'An introduction to Node.js and how to build server-side applications.',
    duration: '50 min',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTukDj4n09DcND8-V5ehNQqbENJabbj_oQtHQ&s',
    link: '/tutorials/intro-to-nodejs',
  },
  {
    title: 'TypeScript Basics',
    description: 'Learn the fundamentals of TypeScript and how it enhances JavaScript development.',
    duration: '40 min',
    image: 'https://media.licdn.com/dms/image/C4E12AQEemHx84dBW3g/article-cover_image-shrink_600_2000/0/1618201226568?e=2147483647&v=beta&t=_43OVnFsnpYCtHfqx5JmuQh4FAP-f7i58PsiEZT0YWY',
    link: '/tutorials/typescript-basics',
  },
];

const TutorialSection = () => {
  return (
    <section className="py-10">
      <div className="container">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Tutorials</h2>

        <Swiper
          spaceBetween={20}
          slidesPerView={3.5}
          breakpoints={{
            320: {
              slidesPerView: 1.1,
            },
            640: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3.5,
            },
          }}
        >
          {tutorials.map((tutorial, index) => (
            <SwiperSlide key={index}>
              <div className="shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <img src={tutorial.image} alt={tutorial.title} className="w-full h-48 object-cover" />
                <div className="p-5">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">{tutorial.title}</h3>
                  <p className="text-gray-600 mb-4">{tutorial.description}</p>
                  <div className="flex items-center text-gray-500 text-sm">
                    <FaRegClock className="mr-2 text-blue-500" />
                    {tutorial.duration}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}

          {/* Button at the End */}
          <SwiperSlide>
            <div className="flex justify-center items-center h-full">
              <button
                onClick={() => window.location.href = '/all-tutorials'}
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 px-8 rounded-full hover:bg-gradient-to-r hover:from-purple-500 hover:to-blue-500 transition-all duration-300 shadow-lg"
              >
                See All Tutorials
              </button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default TutorialSection;
