import React from 'react'
import { FaBriefcase } from 'react-icons/fa'

const Jobs = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <FaBriefcase className="mx-auto text-6xl text-gray-800 animate-bounce" />
        <h1 className="text-4xl font-bold text-gray-800 mt-4">Jobs</h1>
        <p className="mt-4 text-xl text-gray-600">Work in progress, coming soon...</p>
      </div>
    </div>
  )
}

export default Jobs
