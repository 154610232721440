import React from 'react';
import { FaTwitter, FaFacebook, FaLinkedin, FaInstagram, FaEnvelope, FaTelegramPlane } from 'react-icons/fa'; // Added Telegram icon

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-8 pb-20 lg:pb-0 border-t border-gray-200">
      <div className="container mx-auto px-4">
        {/* Footer Bottom Section */}
        <div className="text-center">
          <p className="text-gray-800 text-lg font-semibold mb-2">
            &copy; {new Date().getFullYear()} CampusCoders. All rights reserved.
          </p>
          <div className="flex justify-center space-x-6 mb-4">
            <a
              href="https://x.com/campuscoders"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700 transition-colors duration-300"
              aria-label="Twitter"
            >
              <FaTwitter className="w-6 h-6" />
            </a>
            <a
              href="https://facebook.com/CampusCoder"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
              aria-label="Facebook"
            >
              <FaFacebook className="w-6 h-6" />
            </a>
            <a
              href="https://www.linkedin.com/company/campuscoders/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-700 hover:text-blue-900 transition-colors duration-300"
              aria-label="LinkedIn"
            >
              <FaLinkedin className="w-6 h-6" />
            </a>
            <a
              href="https://www.instagram.com/campuscoders/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-pink-500 hover:text-pink-700 transition-colors duration-300"
              aria-label="Instagram"
            >
              <FaInstagram className="w-6 h-6" />
            </a>
            <a
              href="https://t.me/campuscoders"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-600 transition-colors duration-300"
              aria-label="Telegram"
            >
              <FaTelegramPlane className="w-6 h-6" />
            </a>
          </div>
          <a
            href="mailto:dk119819@gmail.com"
            className="inline-flex items-center text-gray-600 hover:text-gray-800 transition-colors duration-300 mb-4"
            aria-label="Contact Us"
          >
            <FaEnvelope className="w-5 h-5 mr-2" />
            dk119819@gmail.com
          </a>
          <div>
            <a
              href="/privacy-policy"
              className="text-gray-600 hover:underline transition-colors duration-300"
              aria-label="Privacy Policy"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
