// src/components/TutorialDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';

// Sample detailed tutorial data
const tutorialDetails = [
  { id: 1, name: 'Python', details: 'Detailed content about Python programming language with examples and exercises.' },
  { id: 2, name: 'Java', details: 'Detailed content about Java programming language including syntax, OOP principles, and more.' },
  { id: 3, name: 'JavaScript', details: 'Detailed content about JavaScript including ES6 features, async programming, and more.' },
  { id: 4, name: 'Database', details: 'Detailed content about databases, SQL queries, and database design.' },
  { id: 5, name: 'HTML', details: 'Detailed content about HTML including elements, attributes, and best practices.' },
  { id: 6, name: 'CSS', details: 'Detailed content about CSS including selectors, properties, and responsive design.' },
  { id: 7, name: 'React', details: 'Detailed content about React including components, state management, and hooks.' },
  { id: 8, name: 'Node.js', details: 'Detailed content about Node.js including server-side programming and building APIs.' },
];

const TutorialDetail = () => {
  const { id } = useParams(); // Get tutorial ID from URL
  const tutorial = tutorialDetails.find((tut) => tut.id === parseInt(id));

  if (!tutorial) {
    return <div className="container mx-auto px-4 py-6">Tutorial not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-3xl font-bold mb-4">{tutorial.name}</h1>
      <div className="text-gray-700">{tutorial.details}</div>
    </div>
  );
};

export default TutorialDetail;
