import React from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
} from 'chart.js';

// Register chart components
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement
);

// Dummy Data
const userStats = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'New Users',
      data: [50, 60, 70, 80, 90, 100, 110],
      borderColor: '#4c51bf',
      backgroundColor: 'rgba(76, 115, 191, 0.2)',
      fill: true,
      tension: 0.4, // Smooth curve for Line Chart
    },
  ],
};

const postStats = {
  labels: ['Blog Posts', 'Projects', 'Tutorials', 'Cheatsheets', 'Jobs'],
  datasets: [
    {
      label: 'Post Counts',
      data: [45, 23, 37, 15, 8],
      backgroundColor: ['#48bb78', '#f6ad55', '#f56565', '#63b3ed', '#edf2f7'],
    },
  ],
};

const engagementStats = {
  labels: ['Likes', 'Comments', 'Shares'],
  datasets: [
    {
      label: 'Engagement',
      data: [120, 80, 30],
      backgroundColor: ['#f6ad55', '#63b3ed', '#f56565'],
    },
  ],
};

// Component
const Analytics = () => {
  return (
    <div className="p-4 sm:p-6 lg:p-8 space-y-6">
      {/* Title */}
      <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-800 mb-6">
        Dashboard Analytics
      </h1>

      {/* Analytics Overview */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* User Statistics */}
        <div className="bg-white p-4 rounded-lg shadow-md h-80">
          <h2 className="text-lg font-semibold text-gray-700 mb-4">User Growth</h2>
          <div className="h-full">
            <Line
              data={userStats}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </div>
        </div>

        {/* Post Statistics */}
        <div className="bg-white p-4 rounded-lg shadow-md h-80">
          <h2 className="text-lg font-semibold text-gray-700 mb-4">Post Distribution</h2>
          <div className="h-full">
            <Pie
              data={postStats}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'right',
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
              }}
            />
          </div>
        </div>

        {/* Engagement Statistics */}
        <div className="bg-white p-4 rounded-lg shadow-md h-80">
          <h2 className="text-lg font-semibold text-gray-700 mb-4">Engagement Overview</h2>
          <div className="h-full">
            <Bar
              data={engagementStats}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </div>
        </div>
      </div>

      {/* Additional Insights */}
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-lg font-semibold text-gray-700 mb-4">Additional Insights</h2>
        <p className="text-gray-600">
          Here you can add more insights or detailed analytics information, such as trends, comparisons, or forecasts.
        </p>
      </div>
    </div>
  );
};

export default Analytics;
