import React from 'react'
import { FaLightbulb } from 'react-icons/fa'

const Learn = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <FaLightbulb className="mx-auto text-6xl text-yellow-500 animate-pulse" />
        <h1 className="text-4xl font-bold text-gray-800 mt-4">Learn</h1>
        <p className="mt-4 text-xl text-gray-600">Work in progress, coming soon...</p>
      </div>
    </div>
  )
}

export default Learn