import React, { useEffect, useState } from "react";
import appwriteService from "../../Appwrite/Blogconfig"; 
import { Link } from "react-router-dom";

export default function RelatedPosts({ postId }) {
  const [relatedPosts, setRelatedPosts] = useState([]);

  useEffect(() => {
    appwriteService.getRelatedPosts(postId).then((posts) => setRelatedPosts(posts));
  }, [postId]);

  return relatedPosts.length > 0 ? (
    <div className="my-4">
      <h2 className="text-2xl font-bold mb-4">Related Posts</h2>
      <ul>
        {relatedPosts.map((post) => (
          <li key={post.$id} className="mb-2">
            <Link to={`/post/${post.slug}`} className="text-blue-500 hover:underline">
              {post.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
}
