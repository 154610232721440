import React, { useState } from 'react';
import {
  Card,
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Divider,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { FaSave, FaUndo, FaUserCog, FaLock, FaLanguage, FaBell, FaDatabase, FaNetworkWired, FaMobileAlt, FaRegSmile, FaCogs, FaExclamationTriangle } from 'react-icons/fa';
import { MdEmail, MdNotifications } from 'react-icons/md';

// Dummy data
const userRoles = ['Admin', 'Editor', 'Viewer'];
const languages = ['English', 'Spanish', 'French', 'German'];

const SystemSettings = () => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [autoUpdate, setAutoUpdate] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [selectedRole, setSelectedRole] = useState('Admin');

  const handleNotificationChange = (event) => {
    setNotificationsEnabled(event.target.checked);
  };

  const handleAutoUpdateChange = (event) => {
    setAutoUpdate(event.target.checked);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  return (
    <div className="p-6">
      <Grid container spacing={4}>
        {/* User Management */}
        <Grid item xs={12} md={6}>
          <Card className="p-4 shadow-md">
            <Typography variant="h6" className="font-semibold">User Management</Typography>
            <Divider />
            <Box mt={2}>
              <Typography variant="body1">Role</Typography>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Role</InputLabel>
                <Select
                  value={selectedRole}
                  onChange={handleRoleChange}
                  label="Role"
                >
                  {userRoles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box mt={2}>
                <Typography variant="body1">Permissions</Typography>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar><FaUserCog /></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Manage Users" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar><FaLock /></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Change Passwords" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar><FaCogs /></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="System Settings" />
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/* Notifications Settings */}
        <Grid item xs={12} md={6}>
          <Card className="p-4 shadow-md">
            <Typography variant="h6" className="font-semibold">Notification Settings</Typography>
            <Divider />
            <Box mt={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={notificationsEnabled}
                    onChange={handleNotificationChange}
                    color="primary"
                  />
                }
                label="Enable Notifications"
              />
              <Box mt={2}>
                <Typography variant="body1">Notification Preferences</Typography>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar><MdEmail /></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Email Notifications" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar><MdNotifications /></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="In-App Notifications" />
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/* System Configurations */}
        <Grid item xs={12} md={6}>
          <Card className="p-4 shadow-md">
            <Typography variant="h6" className="font-semibold">System Configurations</Typography>
            <Divider />
            <Box mt={2}>
              <Typography variant="body1">Auto Update</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={autoUpdate}
                    onChange={handleAutoUpdateChange}
                    color="primary"
                  />
                }
                label="Enable Auto Updates"
              />
              <Box mt={2}>
                <Typography variant="body1">Language</Typography>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>Language</InputLabel>
                  <Select
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    label="Language"
                  >
                    {languages.map((language) => (
                      <MenuItem key={language} value={language}>
                        {language}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mt={2}>
                <Typography variant="body1">Backup Settings</Typography>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar><FaDatabase /></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Backup Now" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar><FaNetworkWired /></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Network Configuration" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar><FaMobileAlt /></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Mobile App Configuration" />
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Card>
        </Grid>

        {/* System Alerts and Logs */}
        <Grid item xs={12} md={6}>
          <Card className="p-4 shadow-md">
            <Typography variant="h6" className="font-semibold">System Alerts & Logs</Typography>
            <Divider />
            <Box mt={2}>
              <Typography variant="body1">Recent Alerts</Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar><FaExclamationTriangle /></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Critical error detected in system" secondary="5 minutes ago" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar><FaExclamationTriangle /></Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Server load is high" secondary="15 minutes ago" />
                </ListItem>
              </List>
            </Box>
          </Card>
        </Grid>

        {/* Miscellaneous Settings */}
        <Grid item xs={12}>
          <Card className="p-4 shadow-md">
            <Typography variant="h6" className="font-semibold">Miscellaneous Settings</Typography>
            <Divider />
            <Box mt={2} className="flex justify-between">
              <Button variant="contained" color="primary" startIcon={<FaSave />}>Save Changes</Button>
              <Button variant="outlined" color="secondary" startIcon={<FaUndo />}>Undo Changes</Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default SystemSettings;
