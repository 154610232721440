import conf from "../Conf/Conf"; 
import { Client, Account, ID,Query, Avatars, Databases } from "appwrite"; 

export class CommentConfig {
  constructor() {
    this.client = new Client();
    this.account = new Account(this.client);
    this.avatars = new Avatars(this.client);
    this.databases = new Databases(this.client);

    this.client
      .setEndpoint(conf.appwriteUrl) 
      .setProject(conf.appwriteProjectId); 
  }

  // Method to create a new comment
  async createComment({ text, username, postId }) {
    try {
      const response = await this.databases.createDocument(
        conf.appwriteDatabaseId,
        conf.appwriteCommentsCollectionId,
        ID.unique(), 
        {
          text,
          username,
          timestamp: new Date().toISOString(),
          postId,
        }
      );
      return response; 
    } catch (error) {
      console.error("Error creating comment:", error);
      return false; 
    }
  }

  // Method to fetch comments
  async fetchComments(postId) {
    try {
      const response = await this.databases.listDocuments(
        conf.appwriteDatabaseId, 
        conf.appwriteCommentsCollectionId,
        [Query.equal("postId", postId)]
      );
      return response.documents; 
    } catch (error) {
      console.error("Appwrite service :: fetchComments :: error", error);
      return []; 
    }
  }
}