import React from 'react';
import { motion } from 'framer-motion';
import { FaChartLine, FaUsers, FaFileAlt, FaThumbsUp } from 'react-icons/fa';

// Dummy Data
const data = {
  totalPosts: 120,
  totalUsers: 350,
  totalComments: 2000,
  totalLikes: 5000,
};

const Overview = () => {
  return (
    <div className="p-4 sm:p-6 lg:p-8 space-y-6">
      {/* Title */}
      <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-800 mb-6">
        Dashboard Overview
      </h1>

      {/* Summary Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Total Posts */}
        <motion.div
          className="bg-white p-4 rounded-lg shadow-md flex items-center justify-between hover:shadow-lg transition-shadow duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div>
            <h2 className="text-lg font-semibold text-gray-700">Total Posts</h2>
            <p className="text-2xl font-bold text-gray-900">{data.totalPosts}</p>
          </div>
          <div className="bg-blue-500 p-4 rounded-full text-white">
            <FaFileAlt size={24} />
          </div>
        </motion.div>

        {/* Total Users */}
        <motion.div
          className="bg-white p-4 rounded-lg shadow-md flex items-center justify-between hover:shadow-lg transition-shadow duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div>
            <h2 className="text-lg font-semibold text-gray-700">Total Users</h2>
            <p className="text-2xl font-bold text-gray-900">{data.totalUsers}</p>
          </div>
          <div className="bg-green-500 p-4 rounded-full text-white">
            <FaUsers size={24} />
          </div>
        </motion.div>

        {/* Total Comments */}
        <motion.div
          className="bg-white p-4 rounded-lg shadow-md flex items-center justify-between hover:shadow-lg transition-shadow duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div>
            <h2 className="text-lg font-semibold text-gray-700">Total Comments</h2>
            <p className="text-2xl font-bold text-gray-900">{data.totalComments}</p>
          </div>
          <div className="bg-yellow-500 p-4 rounded-full text-white">
            <FaChartLine size={24} />
          </div>
        </motion.div>

        {/* Total Likes */}
        <motion.div
          className="bg-white p-4 rounded-lg shadow-md flex items-center justify-between hover:shadow-lg transition-shadow duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div>
            <h2 className="text-lg font-semibold text-gray-700">Total Likes</h2>
            <p className="text-2xl font-bold text-gray-900">{data.totalLikes}</p>
          </div>
          <div className="bg-red-500 p-4 rounded-full text-white">
            <FaThumbsUp size={24} />
          </div>
        </motion.div>
      </div>

      {/* Additional Information */}
      <motion.div
        className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
        whileHover={{ scale: 1.02 }}
      >
        <h2 className="text-lg font-semibold text-gray-700">Additional Information</h2>
        <p className="text-gray-600 mt-2">
          Here you can add more details or summaries about the site's performance, trends, or upcoming events. This section can be used for providing insights or actionable information.
        </p>
      </motion.div>
    </div>
  );
};

export default Overview;
