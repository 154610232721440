import React, { useState, useEffect } from 'react';
import { CommentConfig } from '../../Appwrite/Commentconfig'; 
import { Container } from '../index';
import { useParams } from 'react-router-dom';
import appwriteService from "../../Appwrite/Blogconfig"; 
import { useSelector } from 'react-redux';
import { FaComment, FaUser } from 'react-icons/fa'; 

const commentConfig = new CommentConfig();

const CommentSection = () => {
  const [comments, setComments] = useState([]);
  const [text, setText] = useState('');
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const { slug } = useParams(); 
  const userData = useSelector((state) => state.auth.userData); 
  const username = userData?.name || 'Anonymous'; 

  useEffect(() => {
    const fetchPostAndComments = async () => {
      setLoading(true);
      try {
        const fetchedPost = await appwriteService.getPost(slug);
        if (fetchedPost) {
          setPost(fetchedPost);
          const fetchedComments = await commentConfig.fetchComments(fetchedPost.$id);
          setComments(fetchedComments);
        }
      } catch (error) {
        console.error("Error fetching post:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchPostAndComments();
  }, [slug]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!post) {
      console.error("Post is not loaded yet."); 
      return;
    }

    try {
      const response = await commentConfig.createComment({ 
        postId: post.$id, 
        text,
        username 
      });

      if (response) {
        setText('');
        setComments(prevComments => [
          ...prevComments, 
          { username, text, timestamp: new Date().toISOString() } // Update state without refetch
        ]);
      }
    } catch (error) {
      console.error("Error submitting comment:", error); 
    }
  };

  return (
    <Container>
      <div className="w-full mx-auto mt-5 bg-white shadow-md rounded-xl p-8 transition-all duration-300 ease-in-out hover:shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Comments</h2>
        {loading ? ( // Show loading state
          <p className="text-gray-500">Loading comments...</p>
        ) : (
          <form onSubmit={handleSubmit} className="mb-4">
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Your Comment"
              className="w-full p-3 mb-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
              required
            />
            <button 
              type="submit" 
              className="w-full flex items-center justify-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
            >
              <FaComment className="mr-2" />
              Comment
            </button>
          </form>
        )}

        <div className="mt-4">
          {comments.length > 0 ? (
            comments.map((comment) => (
              <div key={comment.$id} className="p-2 border-b border-gray-200 flex items-start">
                <FaUser className="mr-2 text-gray-400" /> 
                <div className="flex-1">
                  <h3 className="font-bold">{comment.username}</h3>
                  <p className="text-gray-800">{comment.text}</p>
                  <small className="text-gray-500">
                    {new Date(comment.timestamp).toLocaleString()}
                  </small>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No comments yet. Be the first to comment!</p>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CommentSection;
