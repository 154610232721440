import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../../Contexts/AuthContext';

const AuthConditionallyHiddenRoute = () => {
  const { user } = useContext(AuthContext);

  if (user) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default AuthConditionallyHiddenRoute;
