import React, { useState, useEffect } from 'react';
import {
  Card,
  Grid,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  Select,
  IconButton,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert
} from '@mui/material';
import { FaEdit, FaTrashAlt, FaSearch, FaPlus } from 'react-icons/fa';

const UserManagement = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [userList, setUserList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('add'); // 'add' or 'edit'
  const [currentUser, setCurrentUser] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    // Fetch user data from an API or database
    fetchUserList();
  }, []);

  const fetchUserList = async () => {
    // Replace with your API call
    const dummyUsers = [
      { id: 1, name: 'Aarav Sharma', email: 'aarav.sharma@example.com', role: 'Admin' },
      { id: 2, name: 'Ishaan Patel', email: 'ishaan.patel@example.com', role: 'Moderator' },
      { id: 3, name: 'Vihaan Kumar', email: 'vihaan.kumar@example.com', role: 'User' },
    ];
    setUserList(dummyUsers);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleOpenDialog = (type, user = null) => {
    setDialogType(type);
    setCurrentUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    // Save logic for adding or editing a user
    setSnackbarMessage(dialogType === 'add' ? 'User added successfully!' : 'User updated successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    handleCloseDialog();
    fetchUserList(); // Refresh user list
  };

  const handleDelete = (userId) => {
    // Delete logic
    setSnackbarMessage('User deleted successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    setUserList(userList.filter(user => user.id !== userId));
  };

  const filteredUsers = userList.filter(user =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedRole === '' || user.role === selectedRole)
  );

  return (
    <div className="p-6">
      {/* Search and Filter */}
      <Box mb={4} className="flex items-center justify-between">
        <TextField
          variant="outlined"
          placeholder="Search users..."
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <FaSearch />,
          }}
          className="w-1/3"
        />
        <Select
          value={selectedRole}
          onChange={handleRoleChange}
          displayEmpty
          className="w-1/4"
        >
          <MenuItem value="">All Roles</MenuItem>
          <MenuItem value="Admin">Admin</MenuItem>
          <MenuItem value="Moderator">Moderator</MenuItem>
          <MenuItem value="User">User</MenuItem>
        </Select>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FaPlus />}
          onClick={() => handleOpenDialog('add')}
        >
          Add New User
        </Button>
      </Box>

      {/* User Table */}
      <Card className="p-4 shadow-md">
        <Typography variant="h6" className="font-semibold mb-4">User Management</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleOpenDialog('edit', user)}>
                      <FaEdit />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDelete(user.id)}>
                      <FaTrashAlt />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {/* User Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{dialogType === 'add' ? 'Add New User' : 'Edit User'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            defaultValue={dialogType === 'edit' ? currentUser.name : ''}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            defaultValue={dialogType === 'edit' ? currentUser.email : ''}
          />
          <Select
            label="Role"
            variant="outlined"
            fullWidth
            margin="normal"
            defaultValue={dialogType === 'edit' ? currentUser.role : ''}
          >
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Moderator">Moderator</MenuItem>
            <MenuItem value="User">User</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSave}>{dialogType === 'add' ? 'Add User' : 'Save Changes'}</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserManagement;
