import React from 'react';
import {
  Card,
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  Avatar,
  IconButton,
  Tooltip,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, Legend } from 'recharts';
import { FaUserPlus, FaFileAlt, FaCog, FaChartBar, FaBell, FaEnvelope, FaUserFriends, FaCalendarAlt, FaShoppingCart, FaComments, FaTasks, FaServer, FaExclamationCircle } from 'react-icons/fa';
import { MdPieChart, MdEvent, MdHelpOutline } from 'react-icons/md';

// Dummy data
const dummyUserStats = {
  totalUsers: 1500,
  activeUsers: 1200,
  newSignups: 300
};

const dummyRecentActivity = [
  { description: 'Deepak signed up' },
  { description: 'Raaj Aryan updated profile' },
  { description: 'New comment on blog post "React Hooks"' },
  { description: 'Deepak liked your post' }
];

const dummyPerformanceData = [
  { name: 'Jan', users: 1200 },
  { name: 'Feb', users: 1300 },
  { name: 'Mar', users: 1250 },
  { name: 'Apr', users: 1400 },
  { name: 'May', users: 1500 }
];

const DashboardOverview = () => {
  return (
    <div className="p-6">
      <Grid container spacing={4}>
        {/* Total Users */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className="p-4 shadow-md">
            <Typography variant="h6" className="font-semibold">Total Users</Typography>
            <Typography variant="h4" className="mt-2">{dummyUserStats.totalUsers}</Typography>
          </Card>
        </Grid>

        {/* Active Users */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className="p-4 shadow-md">
            <Typography variant="h6" className="font-semibold">Active Users</Typography>
            <Typography variant="h4" className="mt-2">{dummyUserStats.activeUsers}</Typography>
          </Card>
        </Grid>

        {/* New Signups */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className="p-4 shadow-md">
            <Typography variant="h6" className="font-semibold">New Signups</Typography>
            <Typography variant="h4" className="mt-2">{dummyUserStats.newSignups}</Typography>
          </Card>
        </Grid>
      </Grid>

      <Box mt={4}>
        {/* Recent Activity */}
        <Card className="p-4 shadow-md">
          <Typography variant="h6" className="font-semibold">Recent Activity</Typography>
          <List>
            {dummyRecentActivity.map((activity, index) => (
              <ListItem key={index}>
                <ListItemText primary={activity.description} />
              </ListItem>
            ))}
          </List>
        </Card>
      </Box>

      <Box mt={4}>
        {/* Performance Chart */}
        <Card className="p-4 shadow-md">
          <Typography variant="h6" className="font-semibold">User Growth</Typography>
          <div className="overflow-x-auto">
            <LineChart width={600} height={300} data={dummyPerformanceData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <ChartTooltip />
              <Legend />
              <Line type="monotone" dataKey="users" stroke="#8884d8" />
            </LineChart>
          </div>
        </Card>
      </Box>

      <Box mt={4} className="flex flex-wrap gap-4">
        {/* Quick Actions */}
        <Card className="p-4 shadow-md flex-1">
          <Typography variant="h6" className="font-semibold">Quick Actions</Typography>
          <div className="flex flex-wrap gap-4 mt-2">
            <Button variant="contained" color="primary" startIcon={<FaUserPlus />}>Add New User</Button>
            <Button variant="contained" color="secondary" startIcon={<FaFileAlt />}>View Reports</Button>
            <Button variant="contained" color="success" startIcon={<FaCog />}>Manage Settings</Button>
            <Button variant="contained" color="info" startIcon={<FaChartBar />}>View Analytics</Button>
            <Button variant="contained" color="warning" startIcon={<FaCalendarAlt />}>Schedule Tasks</Button>
            <Button variant="contained" color="error" startIcon={<FaExclamationCircle />}>Alerts</Button>
          </div>
        </Card>
      </Box>

      <Box mt={4} className="flex flex-wrap gap-4">
        {/* Notifications & Messages */}
        <Card className="p-4 shadow-md flex-1 min-w-[300px]">
          <Typography variant="h6" className="font-semibold">Notifications</Typography>
          <Box mt={2}>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar><FaBell /></Avatar>
                </ListItemAvatar>
                <ListItemText primary="New comment on your post" secondary="5 minutes ago" />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemAvatar>
                  <Avatar><FaBell /></Avatar>
                </ListItemAvatar>
                <ListItemText primary="User request for password reset" secondary="10 minutes ago" />
              </ListItem>
            </List>
          </Box>
        </Card>

        <Card className="p-4 shadow-md flex-1 min-w-[300px]">
          <Typography variant="h6" className="font-semibold">Messages</Typography>
          <Box mt={2}>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar><FaEnvelope /></Avatar>
                </ListItemAvatar>
                <ListItemText primary="Welcome email sent to Deepak" secondary="2 hours ago" />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemAvatar>
                  <Avatar><FaEnvelope /></Avatar>
                </ListItemAvatar>
                <ListItemText primary="Follow-up email for feedback" secondary="4 hours ago" />
              </ListItem>
            </List>
          </Box>
        </Card>
      </Box>

      <Box mt={4}>
        {/* User List */}
        <Card className="p-4 shadow-md">
          <Typography variant="h6" className="font-semibold">Active Users</Typography>
          <Box mt={2}>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>DP</Avatar>
                </ListItemAvatar>
                <ListItemText primary="Deepak" secondary="Active" />
                <Chip label="Active" color="success" />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemAvatar>
                  <Avatar>RA</Avatar>
                </ListItemAvatar>
                <ListItemText primary="Raaj Aryan" secondary="Active" />
                <Chip label="Active" color="success" />
              </ListItem>
            </List>
          </Box>
        </Card>
      </Box>

      <Box mt={4}>
        {/* Additional Functionalities */}
        <Grid container spacing={4}>
          {/* System Health */}
          <Grid item xs={12} sm={6} md={4}>
            <Card className="p-4 shadow-md">
              <Typography variant="h6" className="font-semibold">System Health</Typography>
              <Box mt={2}>
                <Typography variant="body2">Server Load: 70%</Typography>
                <Typography variant="body2">Disk Usage: 50%</Typography>
                <Typography variant="body2">Memory Usage: 60%</Typography>
              </Box>
            </Card>
          </Grid>

          {/* User Activity Trends */}
          <Grid item xs={12} sm={6} md={4}>
            <Card className="p-4 shadow-md">
              <Typography variant="h6" className="font-semibold">User Activity Trends</Typography>
              <div className="overflow-x-auto">
                <LineChart width={600} height={300} data={dummyPerformanceData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <ChartTooltip />
                  <Legend />
                  <Line type="monotone" dataKey="users" stroke="#82ca9d" />
                </LineChart>
              </div>
            </Card>
          </Grid>

          {/* Recent Transactions */}
          <Grid item xs={12} sm={6} md={4}>
            <Card className="p-4 shadow-md">
              <Typography variant="h6" className="font-semibold">Recent Transactions</Typography>
              <Box mt={2}>
                <List>
                  <ListItem>
                    <ListItemText primary="Purchase by Deepak - $100" secondary="1 hour ago" />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="Subscription renewal - $50" secondary="3 hours ago" />
                  </ListItem>
                </List>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default DashboardOverview;
