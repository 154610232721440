import React, { useEffect, useReducer, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import appwriteService from "../../Appwrite/Blogconfig";
import { Button, Container, CommentSection, Seo, Loading, RelatedPosts,ShareButtons } from "../index";
import parse, { domToReact } from "html-react-parser";
import { useSelector } from "react-redux";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { AiOutlineCalendar, AiOutlineUser } from "react-icons/ai";
import "tailwindcss/tailwind.css";

const initialState = { post: null, loading: true, error: null };
const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return { ...state, post: action.payload, loading: false };
    case "FETCH_ERROR":
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default function Post() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { slug } = useParams();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.userData);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const post = await appwriteService.getPost(slug);
        if (post) {
          dispatch({ type: "FETCH_SUCCESS", payload: post });
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching post:", error);
        dispatch({ type: "FETCH_ERROR", payload: error.message });
        navigate("/");
      }
    };
    fetchPost();
  }, [slug, navigate]);

  const deletePost = async () => {
    try {
      const status = await appwriteService.deletePost(state.post.$id);
      if (status) {
        await appwriteService.deleteFile(state.post.featuredImage);
        navigate("/");
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      alert("Error deleting post. Please try again.");
    }
  };

  const renderContent = useMemo(() => {
    const options = {
      replace: (domNode) => {
        if (domNode.name === "pre" && domNode.children[0].name === "code") {
          const language = domNode.children[0].attribs.class?.replace("language-", "") || "javascript";
          const codeText = domNode.children[0].children[0].data || "";

          const handleCopyCode = () => {
            navigator.clipboard.writeText(codeText).then(() => {
              alert("Code copied to clipboard!");
            });
          };

          return (
            <div className="relative mb-4">
              <SyntaxHighlighter
                style={materialDark}
                language={language}
                customStyle={{
                  borderRadius: "8px",
                  fontSize: "0.9rem",
                  padding: "1rem",
                  margin: "0",
                  overflowX: "auto",
                }}
              >
                {codeText}
              </SyntaxHighlighter>
              <button
                onClick={handleCopyCode}
                className="absolute top-2 right-2 bg-blue-800 text-white rounded-md px-3 py-2 text-sm transition-all duration-300 hover:bg-blue-700"
              >
                Copy Code
              </button>
            </div>
          );
        }
        return null;
      },
    };

    return state.post ? (
      <div className="prose prose-lg max-w-none text-gray-900">
        {parse(state.post.content, options)}
      </div>
    ) : null;
  }, [state.post]);

  if (state.loading) return <Loading />;
  if (state.error) return <p className="text-red-500">{state.error}</p>;

  const { post } = state;
  const isAuthor = post && userData ? post.userId === userData.$id : false;
  const coverImage = post ? appwriteService.getFilePreview(post.featuredImage) : "";

  return (
    <div className="py-12 bg-gray-50 min-h-screen">
      <Seo
        title={post.title || "Post"}
        description={post.content?.substring(0, 100) || "Description"}
        imageUrl={coverImage}
        imageAlt={post.title || "Post Image"}
        keywords={post.keywords || ""}
        type="Article"
      />
      <Container>
        <h1 className="sm:text-6xl text-3xl text-center font-extrabold mb-6">
          {post.title}
        </h1>
        <div className="flex justify-center items-center text-gray-600 mb-8 space-x-4">
          <span className="flex items-center space-x-2">
            <AiOutlineCalendar />
            <span>
              Posted on: {new Date(post.$createdAt).toLocaleDateString()}
            </span>
          </span>
          <span className="mx-2">•</span>
          <span className="flex items-center space-x-2">
            <AiOutlineUser />
            <span>
              By: <Link to={`/profile/${post.userId}`}>{post.userId?.name || "Unknown Author"}</Link>
            </span>
          </span>
        </div>
        <div className="relative overflow-hidden rounded-xl shadow-lg w-full mx-auto mb-8">
          <img
            src={coverImage}
            alt={post.title}
            className="object-cover w-full h-auto rounded-xl"
          />
          {isAuthor && (
            <div className="absolute top-4 right-4 flex space-x-2">
              <Link to={`/edit-post/${post.$id}`}>
                <Button bgColor="bg-green-500" className="text-sm">
                  Edit
                </Button>
              </Link>
              <Button
                bgColor="bg-red-500"
                className="text-sm"
                onClick={deletePost}
              >
                Delete
              </Button>
            </div>
          )}
        </div>
        {renderContent}
        <ShareButtons url={window.location.href} />
        <CommentSection postId={post.$id} />
        <RelatedPosts postId={post.$id} />
      </Container>
    </div>
  );
}
