import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { Input, Select, Button, RTE } from "../index";
import appwriteService from "../../Appwrite/Blogconfig";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function PostBlogForm({ post }) {
  const { register, handleSubmit, watch, setValue, control, getValues } =
    useForm({
      defaultValues: {
        title: post?.title || "",
        slug: post?.$id || "",
        content: post?.content || "",
        status: post?.status || "active",
        keywords: post?.keywords || "", // Add keywords to default values
      },
    });

  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.userData);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const submit = async (data) => {
    setError(null);
    if (!userData?.$id) {
      setError("User data is missing or invalid. Please login to continue.");
      return;
    }
    setLoading(true);
    try {
      const file = data.image?.[0]
        ? await appwriteService.uploadFile(data.image[0])
        : null;

      if (post) {
        if (file && post?.featuredImage) {
          await appwriteService.deleteFile(post.featuredImage);
        }

        const dbPost = await appwriteService.updatePost(post?.$id, {
          ...data,
          featuredImage: file ? file.$id : post.featuredImage,
        });

        if (dbPost) {
          navigate(`/blogs/${dbPost.$id}`);
        }
      } else {
        if (file) {
          data.featuredImage = file.$id;
        } else {
          setError("Error uploading image.");
          setLoading(false);
          return;
        }

        const dbPost = await appwriteService.createPost({
          ...data,
          userId: userData.$id,
          keywords: data.keywords.split(",").map((k) => k.trim()), // Store keywords as an array
        });

        if (dbPost) {
          navigate(`/blogs/${dbPost.$id}`);
        }
        console.log(dbPost);
      }
    } catch (err) {
      setError("An error occurred while submitting the post.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const slugTransform = useCallback((value) => {
    if (value && typeof value === "string") {
      return value
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z\d\s]+/g, "-")
        .replace(/\s/g, "-");
    }
    return "";
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "title") {
        setValue("slug", slugTransform(value.title), { shouldValidate: true });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, slugTransform, setValue]);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="flex flex-wrap gap-6 lg:flex-nowrap p-6 bg-white shadow-lg rounded-lg"
    >
      <div className="w-full lg:w-2/3 space-y-4">
        <Input
          label="Title"
          placeholder="Enter the post title"
          className="w-full"
          {...register("title", { required: true })}
        />
        <Input
          label="Slug"
          placeholder="Generated slug"
          className="w-full"
          {...register("slug", { required: true })}
          onInput={(e) => {
            setValue("slug", slugTransform(e.currentTarget.value), {
              shouldValidate: true,
            });
          }}
        />
        <RTE
          label="Content"
          name="content"
          control={control}
          defaultValue={getValues("content")}
          className="w-full"
        />
        {/* Keywords input */}
        <Input
          label="Keywords"
          placeholder="Enter keywords separated by commas"
          className="w-full"
          {...register("keywords", { required: false })}
        />
      </div>

      <div className="w-full lg:w-1/3 space-y-4">
        <div>
          <Input
            label="Featured Image"
            type="file"
            className="w-full"
            accept="image/png, image/jpg, image/jpeg, image/gif"
            {...register("image", { required: !post })}
          />
          {post && post.featuredImage && (
            <img
              src={appwriteService.getFilePreview(post.featuredImage)}
              alt={post.title}
              className="mt-4 w-full h-auto rounded-lg"
            />
          )}
        </div>
        <Select
          options={["active", "inactive"]}
          label="Status"
          className="w-full"
          {...register("status", { required: true })}
        />
        {error && <p className="text-red-500">{error}</p>}
        <Button
          type="submit"
          className={`w-full py-2 px-4 rounded-lg ${
            loading ? "bg-gray-400" : post ? "bg-green-500" : "bg-blue-500"
          } text-white`}
        >
          {loading ? "Submitting..." : post ? "Update Post" : "Create Post"}
        </Button>
      </div>
    </form>
  );
}

export default PostBlogForm;
