import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FaLaptopCode, FaMobileAlt, FaPaintBrush, FaPenNib } from 'react-icons/fa';

// Array of services
const services = [
  {
    name: 'Web Development',
    icon: <FaLaptopCode />,
    description: 'Responsive websites built with modern technologies like React, Node.js, and Tailwind CSS.',
  },
  {
    name: 'Mobile App Development',
    icon: <FaMobileAlt />,
    description: 'Cross-platform mobile apps using React Native for seamless performance.',
  },
  {
    name: 'UI/UX Design',
    icon: <FaPaintBrush />,
    description: 'Intuitive and user-friendly designs with a focus on user experience and engagement.',
  },
  {
    name: 'Content Writing',
    icon: <FaPenNib />,
    description: 'High-quality content creation for blogs, websites, and social media.',
  },
  // Additional services if needed
];

const FreelanceSection = () => {
  return (
    <section className="py-12">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Freelance Services</h2>
        <p className="text-center text-lg mb-12 max-w-2xl mx-auto">
          Offering a wide range of freelance services to help you build your brand, enhance your digital presence, and achieve your business goals.
        </p>
        <Swiper
          spaceBetween={20}
          slidesPerView={4.5}
          grabCursor={true}
          breakpoints={{
            100: {
              slidesPerView: 1.5,
            },
            640: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 4.5,
            },
          }}
        >
          {services.map((service, index) => (
            <SwiperSlide
              key={index}
              className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <div className="text-blue-500 text-5xl mb-4 flex justify-center items-center">
                {service.icon}
              </div>
              <h3 className="text-xl font-semibold text-center mb-2">{service.name}</h3>
              <p className="text-center text-gray-600">{service.description}</p>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="text-center mt-12">
          <button className="bg-blue-600 text-white py-3 px-8 rounded-lg hover:bg-blue-700 transition-colors duration-300">
            Get in Touch for Freelance Work
          </button>
        </div>
      </div>
    </section>
  );
};

export default FreelanceSection;
