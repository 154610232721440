import React from 'react'
import { FaProjectDiagram } from 'react-icons/fa'

const Projects = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <FaProjectDiagram className="mx-auto text-6xl text-green-500 animate-bounce" />
        <h1 className="text-4xl font-bold text-gray-800 mt-4">Projects</h1>
        <p className="mt-4 text-xl text-gray-600">Work in progress, coming soon...</p>
      </div>
    </div>
  )
}

export default Projects