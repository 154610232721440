import React, { useState, useEffect } from 'react';
import logo from '../../Assests/Image/logo.svg'; // Update with your logo path

const Loading = () => {
  const [showText, setShowText] = useState(false);
  const [hideAnimation, setHideAnimation] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 1500);

    const hideTimer = setTimeout(() => {
      setHideAnimation(true);
    }, 7000);

    const progressInterval = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 2 : 100));
    }, 70);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(hideTimer);
      clearInterval(progressInterval);
    };
  }, []);

  return (
    <div
      className={`flex flex-col items-center justify-center h-screen bg-white transition-opacity duration-1000 ${
        hideAnimation ? 'opacity-0' : 'opacity-100'
      }`}
    >
      {/* Logo with swipe-up effect */}
      <div
        className={`transition-transform transform ${
          hideAnimation ? 'translate-y-full opacity-0' : 'translate-y-0 opacity-100'
        } duration-1000 ease-in-out`}
      >
        <img 
          src={logo} 
          alt="Logo" 
          className="w-32 h-32 md:w-44 md:h-44 transition-all duration-700 ease-in-out"
        />
      </div>

      {/* Loading Progress Indicator with percentage */}
      <div className="relative w-40 h-1 mt-8 bg-gray-200 rounded-full overflow-hidden">
        <div 
          className="absolute left-0 top-0 h-1 bg-blue-500 transition-all ease-linear"
          style={{ width: `${progress}%` }}
        />
      </div>

      {showText && (
        <div
          className={`mt-4 text-lg md:text-xl font-medium text-gray-600 transition-opacity duration-1000 ease-in-out ${
            hideAnimation ? 'opacity-0' : 'opacity-100'
          }`}
        >
          Loading... {progress}%
        </div>
      )}
    </div>
  );
};

export default Loading;
