import React, { useState, useEffect, useCallback } from "react";
import appwriteService from "../Appwrite/Blogconfig";
import { useNavigate } from "react-router-dom";
import { FaRegCalendarAlt, FaClock } from "react-icons/fa";
import "../App.css"; // Ensure you have the required styles
import { Seo } from "../Components/index";
import blogimage from "../Assests/Image/blogimage.png";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);
  const postsPerPage = 9;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const result = await appwriteService.getPosts();
        if (result) {
          const sortedPosts = result.documents.sort(
            (a, b) => new Date(b.$createdAt) - new Date(a.$createdAt)
          );
          setTotalPosts(sortedPosts.length);
          const start = (currentPage - 1) * postsPerPage;
          const end = start + postsPerPage;
          setPosts(sortedPosts.slice(start, end));
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
        setError("Failed to load posts. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
  }, [currentPage]);

  const calculateReadingTime = (content) => {
    const wordsPerMinute = 200;
    const words = content.split(" ").length;
    return `${Math.ceil(words / wordsPerMinute)} min`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleNextPage = useCallback(() => {
    if (currentPage * postsPerPage < totalPosts) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, totalPosts, postsPerPage]);

  const handlePrevPage = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [currentPage]);

  Seo({
    title: "Latest Blog Posts || The Campus Coders",
    description: "Read the latest blog posts from our community of developers and tech enthusiasts.",
    imageUrl: blogimage,
    imageAlt: "Blog Posts",
    url: "https://www.thecampuscoders.com/blogs",
    keywords: "Developer Blog, Tech Blogs, Coding Tutorials, Programming Articles, Web Development Blogs, JavaScript, Python, React, Node.js, MongoDB, Express, HTML, CSS, UI/UX Design, Software Development, Open Source Projects, GitHub Collaboration"
  });

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-5xl font-bold text-center mb-12 text-gray-900">
        Latest Blog Posts
      </h1>

      {loading ? (
        <div className="flex justify-center items-center h-96">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      ) : error ? (
        <p className="text-center text-red-500">{error}</p>
      ) : (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {posts.map((post) => (
              <div
                key={post.$id}
                className="relative bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl cursor-pointer group"
                onClick={() => navigate(`/blogs/${post.$id}`)}
              >
                <img
                  src={appwriteService.getFilePreview(post.featuredImage)}
                  alt={post.title || "Blog post image"}
                  className="w-full h-48 object-center"
                />
                <div className="p-4">
                  <h2 className="text-2xl font-bold text-gray-800 mb-2">
                    {post.title}
                  </h2>
                  <div className="flex items-center text-gray-500 mb-4">
                    <FaRegCalendarAlt className="mr-2 text-blue-500" />
                    <span>{formatDate(post.$createdAt)}</span>
                    <span className="mx-2">•</span>
                    <FaClock className="mr-2 text-blue-500" />
                    <span>{calculateReadingTime(post.content)}</span>
                  </div>
                </div>
                <div className="absolute inset-0 bg-gray-600 bg-opacity-50 transition-opacity opacity-0 group-hover:opacity-100 flex items-center justify-center">
                  <button className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-transform transform scale-100 group-hover:scale-105 shadow-md">
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-between mt-6">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className={`bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg ${
                currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Previous
            </button>
            <button
              onClick={handleNextPage}
              disabled={currentPage * postsPerPage >= totalPosts}
              className={`bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg ${
                currentPage * postsPerPage >= totalPosts ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;
