import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../Components/Auth/Login';
import Signup from '../Components/Auth/Signup';
import ResetPassword from '../Components/Auth/ResetPassword';

const Auth = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Routes>
  );
};

export default Auth;
