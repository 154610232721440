import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { 
  FaHome, FaChartBar, FaFileAlt, FaProjectDiagram, FaBook, FaBriefcase, 
  FaUserCircle, FaCog, FaSignOutAlt, FaTachometerAlt 
} from 'react-icons/fa';

// Define navItems
const navItems = [
  { path: '/dashboard/overview', label: 'Overview', icon: <FaHome size={20} /> },
  { path: '/dashboard/analytics', label: 'Analytics', icon: <FaChartBar size={20} /> },
  { path: '/dashboard/manage-posts', label: 'Manage Posts', icon: <FaFileAlt size={20} /> },
  { path: '/dashboard/post-blog', label: 'Post Blog', icon: <FaBook size={20} /> },
  { path: '/dashboard/post-job', label: 'Post Job', icon: <FaBriefcase size={20} /> },
  { path: '/dashboard/post-tutorial', label: 'Post Tutorial', icon: <FaBook size={20} /> },
  { path: '/dashboard/post-project', label: 'Post Project', icon: <FaProjectDiagram size={20} /> },
  { path: '/dashboard/post-cheatsheet', label: 'Post Cheatsheet', icon: <FaFileAlt size={20} /> },
  { path: '/dashboard/user-posts', label: 'User Posts', icon: <FaFileAlt size={20} /> },
  { path: '/dashboard/settings', label: 'Settings', icon: <FaCog size={20} /> },
//   { path: '/dashboard', label: 'Dashboard', icon: <FaTachometerAlt size={20} /> },
];

const DashboardSlider = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <div className={`flex ${isSidebarOpen ? 'w-64' : 'w-16'} h-screen bg-gray-800 text-white transition-width duration-300`}>
      <div className="flex flex-col w-full">
        {/* Sidebar Header */}
        <div className="flex items-center justify-between p-4 bg-gray-900">
          <h1 className={`text-xl font-semibold ${!isSidebarOpen && 'hidden'}`}>Dashboard</h1>
          <button
            className="text-2xl focus:outline-none"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            <FaSignOutAlt />
          </button>
        </div>

        {/* Navigation Items */}
        <nav className="flex-1">
          <ul className="mt-2 space-y-2">
            {navItems.map(item => (
              <NavItem
                key={item.path}
                to={item.path}
                icon={item.icon}
                label={item.label}
                isOpen={isSidebarOpen}
              />
            ))}
          </ul>
        </nav>

        {/* Footer */}
        <div className="p-4 bg-gray-900">
          <button className="w-full text-left flex items-center text-red-500 hover:bg-gray-700 p-2 rounded">
            <FaSignOutAlt className="mr-2" />
            <span className={`${!isSidebarOpen && 'hidden'}`}>Logout</span>
          </button>
        </div>
      </div>
    </div>
  );
};

// Navigation Item Component
const NavItem = ({ to, icon, label, isOpen }) => (
  <li>
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center p-3 text-sm font-medium rounded-lg hover:bg-gray-700 transition-colors duration-300 ${
          isActive ? 'bg-gray-700 text-white' : 'text-gray-400'
        }`
      }
    >
      <span className="text-xl">{icon}</span>
      <span className={`ml-3 ${!isOpen && 'hidden'}`}>{label}</span>
    </NavLink>
  </li>
);

export default DashboardSlider;
