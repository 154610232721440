// src/Components/Cheatsheet/CheatsheetDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';

// Sample data
const cheatsheetData = {
  java: {
    title: 'Java Cheatsheet',
    content: 'Detailed Java cheatsheet content goes here...',
  },
  nodejs: {
    title: 'Node.js Cheatsheet',
    content: 'Detailed Node.js cheatsheet content goes here...',
  },
  react: {
    title: 'React Cheatsheet',
    content: 'Detailed React cheatsheet content goes here...',
  },
  javascript: {
    title: 'JavaScript Cheatsheet',
    content: 'Detailed JavaScript cheatsheet content goes here...',
  },
};

const CheatsheetDetail = () => {
  const { id } = useParams();
  const cheatsheet = cheatsheetData[id];

  if (!cheatsheet) {
    return <div className="container mx-auto px-4 py-6">Cheatsheet not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-3xl font-bold mb-6">{cheatsheet.title}</h1>
      <div className="bg-white shadow-md rounded-lg p-6">
        <p>{cheatsheet.content}</p>
      </div>
    </div>
  );
};

export default CheatsheetDetail;
