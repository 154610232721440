import React from 'react';
import { FaBlog, FaProjectDiagram, FaStore, FaBriefcase, FaClipboardList, FaCalendarAlt, FaBook, FaCode } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';

const categories = [
  { path: '/blogs', name: 'Blog', description: 'Read the latest blogs', icon: <FaBlog /> },
  { path: 'https://store.thecampuscoders.com', name: 'Store', description: 'Explore our store', icon: <FaStore /> },
  { path: '/projects', name: 'Project', description: 'Discover innovative projects', icon: <FaProjectDiagram /> },
  { path: '/freelance', name: 'Freelance', description: 'Hire top freelancers', icon: <FaBriefcase /> },
  { path: '/jobs', name: 'Job', description: 'Find the latest jobs', icon: <FaClipboardList /> },
  { path: '/events', name: 'Event', description: 'Explore upcoming events', icon: <FaCalendarAlt /> },
  { path: '/tutorials', name: 'Tutorials', description: 'Learn with our tutorials', icon: <FaBook /> },
  { path: '/cheatsheets', name: 'Cheatsheet', description: 'Access developer cheatsheets', icon: <FaCode /> },
  { path: '/more-categories', name: 'More Categories', description: 'View more categories', icon: <FaClipboardList /> },
];

const FeaturedCategories = () => {
  return (
    <section className="py-8">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Featured Categories</h2>

        <Swiper
          spaceBetween={20}
          slidesPerView={4.5}
          grabCursor={true}
          loop={true}  // Enable infinite loop
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,  // Autoplay pauses on interaction
          }}
          pagination={{ clickable: true }}  // Add pagination bullets
          breakpoints={{
            100: { slidesPerView: 2.5 },
            640: { slidesPerView: 3.5 },
            768: { slidesPerView: 4.5 },
            1024: { slidesPerView: 5.5 },
          }}
        >
          {categories.map((category, index) => (
            <SwiperSlide key={index}>
              <Link
                to={category.path.startsWith('http') ? undefined : category.path} // Use Link for internal paths only
                onClick={() => category.path.startsWith('http') && window.open(category.path, '_blank', 'noopener,noreferrer')} // Open external links in new tab
                className="relative flex flex-col items-center p-6 bg-gradient-to-br from-gray-800 to-gray-700 rounded-lg shadow-lg transition-transform duration-300 hover:scale-110 hover:rounded- hover:shadow-2xl cursor-pointer group"
              >
                <div className="text-5xl mb-4 text-white transition-transform duration-300 transform group-hover:rotate-12 group-hover:text-blue-500">
                  {category.icon}
                </div>
                <h3 className="text-lg font-bold text-white uppercase tracking-wide group-hover:text-blue-500">
                  {category.name}
                </h3>

                {/* Adjusted Tooltip Description */}
                <div className="absolute bottom-full mb-8 left-1/2 transform -translate-x-1/2 p-2 rounded bg-gray-900 text-sm text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {category.description}
                </div>

                {/* Subtle shadow effect on hover */}
                <div className="absolute inset-0 rounded-lg shadow-lg opacity-0 group-hover:opacity-30 transition-opacity duration-300"></div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default FeaturedCategories;
