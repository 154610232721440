import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Store/store";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AuthProvider } from "./Contexts/AuthContext";
import { HelmetProvider } from "react-helmet-async";

import {
  Admin,
  Auth,
  Blog,
  Cheatsheet,
  Dashboard,
  Error,
  Home,
  Jobs,
  Learn,
  Projects,
  SearchPage,
} from "./Pages/index";
import {
  BlogDetails,
  TutorialDetails,
  Profile,
  CheatsheetDetails,
  PrivacyPolicy,
  ProtectedRoute,
  AuthConditionallyHiddenRoute,
} from "./Components/index";

// Define the routes using createBrowserRouter
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/blogs", element: <Blog /> },
      { path: "/blogs/:slug", element: <BlogDetails /> },
      { path: "/learn", element: <Learn /> },
      { path: "/learn/:slug", element: <TutorialDetails /> },
      { path: "/projects", element: <Projects /> },
      { path: "/cheatsheet", element: <Cheatsheet /> },
      { path: "/cheatsheet/:slug", element: <CheatsheetDetails /> },
      { path: "/profile", element: <Profile /> },

      // Protected routes for admin
      {
        element: <ProtectedRoute allowedRoles={["admin"]} />,
        children: [
          { path: "/admin/*", element: <Admin /> },
          { path: "/dashboard/*", element: <Dashboard /> },
        ],
      },

      { path: "/jobs", element: <Jobs /> },
      { path: "/search", element: <SearchPage /> },
      {
        element: <AuthConditionallyHiddenRoute />,
        children: [{ path: "/auth/*", element: <Auth /> }],
      },

      { path: "*", element: <Error /> },
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <HelmetProvider>
          <RouterProvider router={router} />
        </HelmetProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
