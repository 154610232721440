import React, { useState, startTransition } from 'react';
import { FaLock } from 'react-icons/fa';
import AuthService from '../../Appwrite/Auth'; // Adjust the path as needed

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const authService = new AuthService();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    startTransition(async () => {
      try {
        await authService.sendPasswordResetEmail(email);
        setMessage('Password reset link sent to your email address.');
      } catch (err) {
        setError(err.message);
        console.log('Reset password error:', err);
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
          Reset Password
        </h2>
        <p className="text-center text-gray-600 mb-8">
          Enter your email address to receive a password reset link.
        </p>
        {message && (
          <div className="bg-green-100 text-green-700 p-4 rounded mb-4">
            {message}
          </div>
        )}
        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded mb-4">
            {error}
          </div>
        )}
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
              required
              autoComplete="email"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Send Reset Link'}
            </button>
          </div>
        </form>
        <p className="text-center text-gray-600 text-sm mt-4">
          Remembered your password?{' '}
          <a href="/Auth/login" className="text-blue-500 hover:text-blue-800">
            Log in
          </a>
        </p>
        <div className="flex justify-center mt-6">
          <FaLock className="text-gray-600 text-3xl" />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
