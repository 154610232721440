import React, { useState, useEffect } from 'react';
import {
  Card,
  Grid,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  Select,
  IconButton,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert
} from '@mui/material';
import { FaSearch, FaPlus, FaCheck, FaTimes, FaExclamationTriangle } from 'react-icons/fa';

const ContentModeration = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [contentList, setContentList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('view'); // 'view', 'approve', 'reject'
  const [currentContent, setCurrentContent] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    // Fetch content data from an API or database
    fetchContentList();
  }, []);

  const fetchContentList = async () => {
    // Replace with your API call
    const dummyContent = [
      { id: 1, title: 'Inappropriate Content 1', status: 'Pending' },
      { id: 2, title: 'Spam Content 2', status: 'Pending' },
      { id: 3, title: 'Offensive Content 3', status: 'Approved' },
    ];
    setContentList(dummyContent);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleOpenDialog = (type, content = null) => {
    setDialogType(type);
    setCurrentContent(content);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleApprove = () => {
    // Approve content logic
    setSnackbarMessage('Content approved successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    handleCloseDialog();
    fetchContentList(); // Refresh content list
  };

  const handleReject = () => {
    // Reject content logic
    setSnackbarMessage('Content rejected successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    handleCloseDialog();
    fetchContentList(); // Refresh content list
  };

  const handleReport = () => {
    // Report content logic
    setSnackbarMessage('Content reported successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    handleCloseDialog();
    fetchContentList(); // Refresh content list
  };

  const filteredContent = contentList.filter(content =>
    content.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedStatus === '' || content.status === selectedStatus)
  );

  return (
    <div className="p-6">
      {/* Search and Filter */}
      <Box mb={4} className="flex items-center justify-between">
        <TextField
          variant="outlined"
          placeholder="Search content..."
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <FaSearch />,
          }}
          className="w-1/3"
        />
        <Select
          value={selectedStatus}
          onChange={handleStatusChange}
          displayEmpty
          className="w-1/4"
        >
          <MenuItem value="">All Statuses</MenuItem>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Approved">Approved</MenuItem>
          <MenuItem value="Rejected">Rejected</MenuItem>
        </Select>
      </Box>

      {/* Content Table */}
      <Card className="p-4 shadow-md">
        <Typography variant="h6" className="font-semibold mb-4">Content Moderation</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredContent.map((content) => (
                <TableRow key={content.id}>
                  <TableCell>{content.title}</TableCell>
                  <TableCell>{content.status}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleOpenDialog('view', content)}>
                      <FaSearch />
                    </IconButton>
                    {content.status === 'Pending' && (
                      <>
                        <IconButton color="success" onClick={() => handleOpenDialog('approve', content)}>
                          <FaCheck />
                        </IconButton>
                        <IconButton color="secondary" onClick={() => handleOpenDialog('reject', content)}>
                          <FaTimes />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {/* Content Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{dialogType === 'view' ? 'Content Details' : dialogType === 'approve' ? 'Approve Content' : 'Reject Content'}</DialogTitle>
        <DialogContent>
          {dialogType === 'view' ? (
            <Box>
              <Typography variant="h6" className="font-semibold">Title:</Typography>
              <Typography variant="body1">{currentContent?.title}</Typography>
              <Typography variant="h6" className="font-semibold mt-2">Status:</Typography>
              <Typography variant="body1">{currentContent?.status}</Typography>
            </Box>
          ) : (
            <Typography variant="body1">
              Are you sure you want to {dialogType} this content?
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          {dialogType === 'approve' && <Button onClick={handleApprove}>Approve</Button>}
          {dialogType === 'reject' && <Button onClick={handleReject}>Reject</Button>}
          {dialogType === 'view' && <Button onClick={handleReport}>Report</Button>}
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContentModeration;
