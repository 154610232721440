import conf from '../Conf/Conf';
import { Client, ID, Databases, Storage, Query } from "appwrite";

export class Service {
    client = new Client();
    databases;
    bucket;

    constructor() {
        this.client
            .setEndpoint(conf.appwriteUrl)
            .setProject(conf.appwriteProjectId);
        this.databases = new Databases(this.client);
        this.bucket = new Storage(this.client);
    }

    // Create Post
    async createPost({ title, slug, content, featuredImage, status, userId, keywords }) {
        try {
            const documentId = slug || ID.unique();  // Use slug as document ID if available
    
            return await this.databases.createDocument(
                conf.appwriteDatabaseId,
                conf.appwriteBlogCollectionId,
                documentId,
                {
                    title,
                    content,
                    featuredImage,
                    status,
                    userId,
                    keywords // Add keywords to the document creation
                }
            );
        } catch (error) {
            console.error("Appwrite service :: createPost :: error", error);
    
            if (error.code === 409) {
                console.warn("Document with the requested ID already exists.");
            }
            return false;
        }
    }

    // Update Post
    async updatePost(slug, { title, content, featuredImage, status }) {
        try {
            return await this.databases.updateDocument(
                conf.appwriteDatabaseId,
                conf.appwriteBlogCollectionId,
                slug,
                {
                    title,
                    content,
                    featuredImage,
                    status,
                }
            );
        } catch (error) {
            console.error("Appwrite service :: updatePost :: error", error);
            return false;
        }
    }

    // Delete Post
    async deletePost(slug) {
        try {
            await this.databases.deleteDocument(
                conf.appwriteDatabaseId,
                conf.appwriteBlogCollectionId,
                slug
            );
            return true;
        } catch (error) {
            console.error("Appwrite service :: deletePost :: error", error);
            return false;
        }
    }

    // Get a Single Post
    async getPost(slug) {
        try {
            return await this.databases.getDocument(
                conf.appwriteDatabaseId,
                conf.appwriteBlogCollectionId,
                slug
            );
        } catch (error) {
            console.error("Appwrite service :: getPost :: error", error);
            return false;
        }
    }

    // Get All Posts
    async getPosts(queries = [Query.equal("status", "active")]) {
        try {
            return await this.databases.listDocuments(
                conf.appwriteDatabaseId,
                conf.appwriteBlogCollectionId,
                queries
            );
        } catch (error) {
            console.error("Appwrite service :: getPosts :: error", error);
            return false;
        }
    }

    // Get Related Posts (based on specific criteria like tags or categories)
    async getRelatedPosts(postId, queries = []) {
        try {
            queries.push(Query.notEqual('$id', postId));  // Exclude current post
            return await this.databases.listDocuments(
                conf.appwriteDatabaseId,
                conf.appwriteBlogCollectionId,
                queries
            );
        } catch (error) {
            console.error("Appwrite service :: getRelatedPosts :: error", error);
            return [];
        }
    }

    // File Upload Service
    async uploadFile(file) {
        try {
            return await this.bucket.createFile(
                conf.appwriteBucketId,
                ID.unique(),
                file
            );
        } catch (error) {
            console.error("Appwrite service :: uploadFile :: error", error);
            return false;
        }
    }

    // Delete File
    async deleteFile(fileId) {
        try {
            await this.bucket.deleteFile(conf.appwriteBucketId, fileId);
            return true;
        } catch (error) {
            console.error("Appwrite service :: deleteFile :: error", error);
            return false;
        }
    }

    // Get File Preview
    getFilePreview(fileId) {
        try {
            return this.bucket.getFilePreview(conf.appwriteBucketId, fileId).href;
        } catch (error) {
            console.error("Appwrite service :: getFilePreview :: error", error);
            return '';
        }
    }
}

// Export an instance of the service
const service = new Service();
export default service;