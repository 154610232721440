import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaEdit, FaTrashAlt, FaSearch } from 'react-icons/fa';

// Dummy Data
const dummyPosts = [
  { id: 1, title: 'First Post', author: 'John Doe', date: '2024-08-21', status: 'Published' },
  { id: 2, title: 'Second Post', author: 'Jane Smith', date: '2024-08-20', status: 'Draft' },
  { id: 3, title: 'Third Post', author: 'Alice Johnson', date: '2024-08-19', status: 'Published' },
  // Add more dummy data as needed
];

const ManagePosts = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [posts, setPosts] = useState(dummyPosts);

  // Filter posts based on search term
  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-4 sm:p-6 lg:p-8 space-y-6">
      {/* Title */}
      <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-800">Manage Posts</h1>

      {/* Search Bar */}
      <div className="flex items-center mb-4">
        <input
          type="text"
          placeholder="Search posts..."
          className="border rounded-lg p-2 w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="ml-2 bg-blue-500 text-white p-2 rounded-lg flex items-center">
          <FaSearch size={16} />
        </button>
      </div>

      {/* Posts Table */}
      <motion.div
        className="bg-white p-4 rounded-lg shadow-md"
        whileHover={{ scale: 1.02 }}
      >
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Author</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredPosts.length > 0 ? (
              filteredPosts.map(post => (
                <tr key={post.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{post.title}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{post.author}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{post.date}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{post.status}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button className="text-blue-500 hover:text-blue-700 mr-2">
                      <FaEdit size={16} />
                    </button>
                    <button className="text-red-500 hover:text-red-700">
                      <FaTrashAlt size={16} />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-500">No posts found</td>
              </tr>
            )}
          </tbody>
        </table>
      </motion.div>
    </div>
  );
};

export default ManagePosts;
