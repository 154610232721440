import React from 'react';
import { FaLinkedin, FaTwitter, FaGithub } from 'react-icons/fa';

// Sample team member data with a placeholder image URL
const teamMembers = [
  {
    id: 1,
    name: 'Deepak Kumar',
    position: 'MERN Stack Developer',
    bio: 'Passionate about web development and creating interactive user experiences.',
    photo: 'https://i.pinimg.com/736x/32/a1/da/32a1da970e70c746a449b5cccabaeff5.jpg', // Placeholder image URL
    linkedin: 'https://linkedin.com/in/raajaryan',
    twitter: 'https://x.com/dk_raajaryan',
    github: 'https://github.com/deepakkumar55'
  },
  
];

const TeamMembers = () => {
  return (
    <section className="py-10">
      <div className="container">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Meet the Team</h2>
        <div className="flex justify-center">
          {teamMembers.map((member) => (
            <div key={member.id} className="bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 max-w-sm mx-4">
              <div className="flex justify-center mt-4">
                <img
                  src={member.photo}
                  alt={member.name}
                  className="w-32 h-32 object-cover rounded-full border-4 border-gray-200"
                />
              </div>
              <div className="p-6">
                <h3 className="text-2xl font-semibold mb-2 text-center">{member.name}</h3>
                <p className="text-gray-600 mb-2 text-center">{member.position}</p>
                <p className="text-gray-500 mb-4 text-center">{member.bio}</p>
                <div className="flex justify-center space-x-4">
                  {member.linkedin && (
                    <a href={member.linkedin} className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">
                      <FaLinkedin size={24} />
                    </a>
                  )}
                  {member.twitter && (
                    <a href={member.twitter} className="text-blue-400 hover:text-blue-600" target="_blank" rel="noopener noreferrer">
                      <FaTwitter size={24} />
                    </a>
                  )}
                  {member.github && (
                    <a href={member.github} className="text-gray-800 hover:text-gray-600" target="_blank" rel="noopener noreferrer">
                      <FaGithub size={24} />
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamMembers;
