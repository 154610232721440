import React, { useState } from 'react';
import { Button, Input, Upload, DatePicker, Select, Modal, notification, Tag } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import "antd/dist/reset.css";
import "tailwindcss/tailwind.css";

const { Option } = Select;
const { TextArea } = Input;

const PostProjectForm = () => {
  // State hooks
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [files, setFiles] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [projectDate, setProjectDate] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Handler functions
  const handleFileUpload = async (info) => {
    if (info.file.status === 'done') {
      const url = URL.createObjectURL(info.file.originFileObj);
      setFileUrls([...fileUrls, url]);
      setFiles([...files, info.file.originFileObj]);
    } else if (info.file.status === 'uploading') {
      // Optional: Display upload progress or status
    }
  };

  const handleSubmit = async () => {
    if (!title || !description || !category) {
      notification.error({
        message: 'Form Error',
        description: 'Please fill out all required fields.',
      });
      return;
    }

    setIsLoading(true);

    // Submit project data logic
    const project = {
      title,
      description,
      category,
      files,
      projectDate,
    };

    try {
      // Simulating API call
      console.log('Submitting project:', project);
      notification.success({
        message: 'Success',
        description: 'Project posted successfully!',
      });
      resetForm();
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an issue submitting your project.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setCategory('');
    setFiles([]);
    setFileUrls([]);
    setProjectDate(null);
  };

  const confirmExit = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: 'You have unsaved changes!',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to leave without saving your changes?',
      onOk: () => {
        window.location.href = '/';
      },
    });
  };

  const togglePreview = () => {
    if (!title || !description) {
      notification.warning({
        message: 'Preview Error',
        description: 'Please enter a title and description before previewing.',
      });
      return;
    }
    setShowPreview(!showPreview);
  };

  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-lg max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Post a New Project</h2>
      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} onReset={resetForm}>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Project Title"
          required
          className="mb-4 border border-gray-300 rounded-lg px-4 py-2 w-full"
        />
        <TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Project Description"
          required
          className="mb-4 border border-gray-300 rounded-lg px-4 py-2 w-full"
        />
        <Select
          value={category}
          onChange={setCategory}
          placeholder="Select Category"
          required
          className="mb-4 w-full"
        >
          <Option value="web-development">Web Development</Option>
          <Option value="mobile-app">Mobile App</Option>
          <Option value="data-science">Data Science</Option>
          <Option value="design">Design</Option>
          <Option value="other">Other</Option>
        </Select>
        <Upload
          name="files"
          multiple
          showUploadList={false}
          beforeUpload={() => false}
          onChange={handleFileUpload}
        >
          <Button icon={<PlusOutlined />} className="mb-4">
            Upload Project Files
          </Button>
        </Upload>
        <div className="mb-4">
          {fileUrls.map((url, index) => (
            <div key={index} className="flex items-center mb-2">
              <a href={url} download={`file${index + 1}`} className="mr-2">
                <Button icon={<DownloadOutlined />} size="small">Download</Button>
              </a>
              <span>File {index + 1}</span>
            </div>
          ))}
        </div>
        <DatePicker
          value={projectDate}
          onChange={setProjectDate}
          placeholder="Select Project Date"
          className="mb-4 w-full"
        />
        <div className="flex justify-between items-center">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="mr-4"
          >
            Submit
          </Button>
          <Button htmlType="reset" onClick={resetForm} className="mr-4">
            Reset
          </Button>
          <Button onClick={togglePreview}>
            {showPreview ? "Hide Preview" : "Show Preview"}
          </Button>
        </div>
      </form>
      {showPreview && (
        <Modal
          visible={showPreview}
          title="Project Preview"
          onCancel={togglePreview}
          footer={[
            <Button key="back" onClick={togglePreview}>
              Close
            </Button>,
          ]}
        >
          <h2>{title}</h2>
          <div><strong>Description:</strong> {description}</div>
          <div><strong>Category:</strong> {category}</div>
          <div><strong>Project Date:</strong> {projectDate ? projectDate.format('YYYY-MM-DD') : 'Not set'}</div>
          <div>
            <strong>Files:</strong>
            {fileUrls.map((url, index) => (
              <div key={index} className="flex items-center mt-2">
                <a href={url} download={`file${index + 1}`} className="mr-2">
                  <Button icon={<DownloadOutlined />} size="small">Download</Button>
                </a>
                <span>File {index + 1}</span>
              </div>
            ))}
          </div>
        </Modal>
      )}
      <button onClick={confirmExit} className="mt-4 bg-red-500 text-white px-4 py-2 rounded">
        Exit Without Saving
      </button>
    </div>
  );
};

export default PostProjectForm;
