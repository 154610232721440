import React, { useState } from 'react';
import { Button, Input, Select, Upload, DatePicker, InputNumber, Tag, notification, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import "antd/dist/reset.css";
import "tailwindcss/tailwind.css";

const { Option } = Select;
const { TextArea } = Input;

const PostJobForm = () => {
  // State hooks
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [requirements, setRequirements] = useState('');
  const [location, setLocation] = useState('');
  const [jobType, setJobType] = useState('');
  const [salary, setSalary] = useState('');
  const [salaryRange, setSalaryRange] = useState([0, 100000]);
  const [tags, setTags] = useState([]);
  const [deadline, setDeadline] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoUrl, setCompanyLogoUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  // Handler functions
  const handleTagInput = (tag) => {
    if (tag && !tags.includes(tag)) {
      setTags([...tags, tag]);
    }
  };

  const handleImageUpload = async (info) => {
    if (info.file.status === 'done') {
      try {
        const reader = new FileReader();
        reader.onload = () => setCompanyLogoUrl(reader.result);
        reader.readAsDataURL(info.file.originFileObj);
        setCompanyLogo(info.file.originFileObj);
      } catch (error) {
        notification.error({
          message: 'Image Upload Error',
          description: 'Failed to upload the image. Please try again.',
        });
      }
    }
  };

  const handleSubmit = async () => {
    if (!title || !description || !location || !jobType || !salary) {
      notification.error({
        message: 'Form Error',
        description: 'Please fill out all required fields.',
      });
      return;
    }

    setIsLoading(true);

    // Submit form logic
    const jobPost = {
      title,
      description,
      requirements,
      location,
      jobType,
      salary,
      salaryRange,
      tags,
      deadline,
      companyLogo,
    };

    try {
      // Simulating API call
      console.log('Submitting job post:', jobPost);
      notification.success({
        message: 'Success',
        description: 'Job post submitted successfully!',
      });
      resetForm();
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an issue submitting your job post.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setRequirements('');
    setLocation('');
    setJobType('');
    setSalary('');
    setSalaryRange([0, 100000]);
    setTags([]);
    setDeadline(null);
    setCompanyLogo(null);
    setCompanyLogoUrl(null);
  };

  const confirmExit = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: 'You have unsaved changes!',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to leave without saving your changes?',
      onOk: () => {
        window.location.href = '/';
      },
    });
  };

  const togglePreview = () => {
    if (!title || !description) {
      notification.warning({
        message: 'Preview Error',
        description: 'Please enter a title and description before previewing.',
      });
      return;
    }
    setShowPreview(!showPreview);
  };

  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-lg max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Post a New Job</h2>
      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} onReset={resetForm}>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Job Title"
          required
          className="mb-4 border border-gray-300 rounded-lg px-4 py-2 w-full"
        />
        <TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Job Description"
          required
          className="mb-4 border border-gray-300 rounded-lg px-4 py-2 w-full"
        />
        <TextArea
          value={requirements}
          onChange={(e) => setRequirements(e.target.value)}
          placeholder="Job Requirements"
          className="mb-4 border border-gray-300 rounded-lg px-4 py-2 w-full"
        />
        <Input
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Job Location"
          required
          className="mb-4 border border-gray-300 rounded-lg px-4 py-2 w-full"
        />
        <Select
          value={jobType}
          onChange={setJobType}
          placeholder="Select Job Type"
          required
          className="mb-4 w-full"
        >
          <Option value="full-time">Full-Time</Option>
          <Option value="part-time">Part-Time</Option>
          <Option value="contract">Contract</Option>
          <Option value="internship">Internship</Option>
        </Select>
        <InputNumber
          value={salary}
          onChange={(value) => setSalary(value)}
          placeholder="Salary"
          className="mb-4 w-full"
        />
        <div className="mb-4">
          <span>Salary Range:</span>
          <InputNumber
            min={0}
            max={100000}
            value={salaryRange[0]}
            onChange={(value) => setSalaryRange([value, salaryRange[1]])}
            className="mr-2"
          />
          -
          <InputNumber
            min={0}
            max={100000}
            value={salaryRange[1]}
            onChange={(value) => setSalaryRange([salaryRange[0], value])}
            className="ml-2"
          />
        </div>
        <Input
          placeholder="Enter tags (Press Enter to add)"
          onPressEnter={(e) => {
            handleTagInput(e.target.value);
            e.target.value = '';
          }}
          className="mb-4 border border-gray-300 rounded-lg px-4 py-2 w-full"
        />
        <div className="mt-2">
          {tags.map((tag, index) => (
            <Tag
              key={index}
              closable
              onClose={() => setTags(tags.filter((t) => t !== tag))}
            >
              {tag}
            </Tag>
          ))}
        </div>
        <Upload
          name="file"
          listType="picture"
          showUploadList={false}
          beforeUpload={() => false}
          onChange={handleImageUpload}
        >
          <Button icon={<PlusOutlined />} className="mb-4">
            Upload Company Logo
          </Button>
        </Upload>
        {companyLogoUrl && (
          <img
            src={companyLogoUrl}
            alt="Company Logo"
            className="mb-4 max-h-60 object-cover"
          />
        )}
        <DatePicker
          value={deadline}
          onChange={setDeadline}
          placeholder="Select Application Deadline"
          className="mb-4 w-full"
        />
        <div className="flex justify-between items-center">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="mr-4"
          >
            Submit
          </Button>
          <Button htmlType="reset" onClick={resetForm} className="mr-4">
            Reset
          </Button>
          <Button onClick={togglePreview}>
            {showPreview ? "Hide Preview" : "Show Preview"}
          </Button>
        </div>
      </form>
      {showPreview && (
        <Modal
          visible={showPreview}
          title="Job Post Preview"
          onCancel={togglePreview}
          footer={[
            <Button key="back" onClick={togglePreview}>
              Close
            </Button>,
          ]}
        >
          <h2>{title}</h2>
          <img src={companyLogoUrl} alt="Company Logo" className="mb-4 max-h-60 object-cover" />
          <div><strong>Description:</strong> {description}</div>
          <div><strong>Requirements:</strong> {requirements}</div>
          <div><strong>Location:</strong> {location}</div>
          <div><strong>Job Type:</strong> {jobType}</div>
          <div><strong>Salary:</strong> ${salary}</div>
          <div><strong>Salary Range:</strong> ${salaryRange[0]} - ${salaryRange[1]}</div>
          <div><strong>Tags:</strong> {tags.join(', ')}</div>
          <div><strong>Application Deadline:</strong> {deadline ? deadline.format('YYYY-MM-DD') : 'Not set'}</div>
        </Modal>
      )}
      <button onClick={confirmExit} className="mt-4 bg-red-500 text-white px-4 py-2 rounded">
        Exit Without Saving
      </button>
    </div>
  );
};

export default PostJobForm;
