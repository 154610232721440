import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FaArrowRight, FaClock, FaRegComments } from 'react-icons/fa';
import appwriteService from "../../Appwrite/Blogconfig"; // Import your Appwrite service
import parse from "html-react-parser"; // Import parse for rendering HTML
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing

const LatestBlog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const blogsPerPage = 5; // Display 5 blogs per page
  const navigate = useNavigate(); // Hook for navigating

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const result = await appwriteService.getPosts();
        if (result && result.documents) {
          const sortedPosts = result.documents.sort((a, b) => new Date(b.$createdAt) - new Date(a.$createdAt));
          setTotalBlogs(sortedPosts.length); // Total number of blogs
          const start = (currentPage - 1) * blogsPerPage;
          const end = start + blogsPerPage;
          setBlogs(sortedPosts.slice(start, end)); // Slice to get current page blogs
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
  }, [currentPage]);

  // Helper function to calculate reading time
  const calculateReadingTime = (content) => {
    const wordsPerMinute = 200; // Average reading speed
    const words = content.split(' ').length;
    return Math.ceil(words / wordsPerMinute);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <p className="text-gray-500">Loading...</p>
      </div>
    );
  }

  return (
    <section className="py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Latest Blogs</h2>

        {/* Blog Carousel */}
        <Swiper
          spaceBetween={30}
          slidesPerView={1.5}
          pagination={{ clickable: true }}
          breakpoints={{
            320: {
              slidesPerView: 1.1,
            },
            640: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3.5,
            },
          }}
        >
          {blogs.map((blog) => (
            <SwiperSlide key={blog.$id} className="relative group">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:bg-opacity-70">
                <img src={appwriteService.getFilePreview(blog.featuredImage)} alt={blog.title} className="w-full h-48 object-cover" />
                <div className="p-4">
                  <h3 className="text-lg font-semibold mb-2">{blog.title}</h3>
                  <div className="flex items-center justify-between text-gray-500 text-sm mb-2">
                    <span className="flex items-center">
                      <FaClock className="mr-1" />
                      {new Date(blog.$createdAt).toLocaleDateString()}
                    </span>
                    <span className="flex items-center">
                      <FaRegComments className="mr-1" />
                      {blog.comments || 0} comments
                    </span>
                  </div>
                  <span className="text-sm text-gray-700">{calculateReadingTime(blog.content)} min read</span>
                </div>
              </div>
              {/* Read More button overlay on hover */}
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition duration-300 flex items-center justify-center opacity-0 group-hover:opacity-100 backdrop-blur">
                <button 
                  onClick={() => navigate(`/blogs/${blog.$id}`)} // Navigate to the blog post
                  className="text-blue-500 bg-white px-4 py-2 rounded-lg hover:bg-blue-500 hover:text-white transition duration-300"
                >
                  Read More
                </button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default LatestBlog;
