import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaUsers, FaFileAlt, FaCogs, FaChartLine, FaSignOutAlt, FaTachometerAlt } from 'react-icons/fa';

const AdminSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <div className={`flex ${isSidebarOpen ? 'w-64' : 'w-16'} h-screen bg-gray-800 text-white transition-width duration-300`}>
      <div className="flex flex-col w-full">
        {/* Sidebar Header */}
        <div className="flex items-center justify-between p-4 bg-gray-900">
          <h1 className={`text-xl font-semibold ${!isSidebarOpen && 'hidden'}`}>Admin Panel</h1>
          <button
            className="text-2xl focus:outline-none"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            {isSidebarOpen ? <FaSignOutAlt /> : <FaSignOutAlt />}
          </button>
        </div>

        {/* Navigation Items */}
        <nav className="flex-1">
          <ul className="mt-2 space-y-2">
            <NavItem to="/admin/dashboard " icon={<FaTachometerAlt />} label="Dashboard" isOpen={isSidebarOpen} />
            <NavItem to="/admin/users" icon={<FaUsers />} label="Users" isOpen={isSidebarOpen} />
            <NavItem to="/admin/content" icon={<FaFileAlt />} label="Posts" isOpen={isSidebarOpen} />
            <NavItem to="/admin/settings" icon={<FaCogs />} label="Settings" isOpen={isSidebarOpen} />
            <NavItem to="/admin/analytics" icon={<FaChartLine />} label="Analytics" isOpen={isSidebarOpen} />
            {/* Add more NavItem components for other functionalities */}
          </ul>
        </nav>

        {/* Footer */}
        <div className="p-4 bg-gray-900">
          <button className="w-full text-left flex items-center text-red-500 hover:bg-gray-700 p-2 rounded">
            <FaSignOutAlt className="mr-2" />
            <span className={`${!isSidebarOpen && 'hidden'}`}>Logout</span>
          </button>
        </div>
      </div>
    </div>
  );
};

// Navigation Item Component
const NavItem = ({ to, icon, label, isOpen }) => (
  <li>
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center p-3 text-sm font-medium rounded-lg hover:bg-gray-700 ${
          isActive ? 'bg-gray-700 text-white' : 'text-gray-400'
        }`
      }
    >
      <span className="text-xl">{icon}</span>
      <span className={`ml-3 ${!isOpen && 'hidden'}`}>{label}</span>
    </NavLink>
  </li>
);

export default AdminSidebar;
