import React from 'react';
import { motion } from 'framer-motion';
import { FaArrowRight } from 'react-icons/fa';

const CalltoAction = () => {
  return (
    <section className="relative py-16 text-center overflow-hidden">
      {/* Decorative shape */}
      <div className="absolute inset-0 -z-10"></div>
      
      <div className="container mx-auto px-6 relative z-10">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="text-4xl md:text-5xl font-bold mb-6 text-gray-800 font-display"
        >
          Join Our Community Today!
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="text-lg md:text-xl mb-8 text-gray-600"
        >
          Connect with professionals, access exclusive resources, and accelerate your career. 
          We offer a range of tools and opportunities to help you succeed.
        </motion.p>
        <motion.a
          href="/signup"
          initial={{ scale: 1 }}
          whileHover={{ scale: 1.05, rotate: 5 }}
          whileTap={{ scale: 0.95 }}
          transition={{ type: 'spring', stiffness: 300 }}
          className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-blue-900 bg-yellow-400 rounded-full group"
        >
          <span className="absolute inset-0 w-full h-full transition-all duration-300 transform -translate-x-full bg-gradient-to-r from-blue-500 to-purple-500 group-hover:translate-x-0"></span>
          <span className="relative text-lg font-semibold">Get Started</span>
          <FaArrowRight className="relative text-xl ml-2 transition-transform duration-300 group-hover:translate-x-2" />
        </motion.a>
      </div>
    </section>
  );
};

export default CalltoAction;
