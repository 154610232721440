// Home.js
import React from 'react';
import cam from '../Assests/Image/CampusCoders.png';
import {
  HeroBanner,
  FeaturedCategories,
  UpcomingEvents,
  LatestBlogs,
  ProjectSection,
  TutorialSection,
  UsefulResources,
  FreelanceSection,
  CareerAdvice,
  TeamMembers,
  CallToAction,
  Seo,
} from '../Components';

const Home = () => {
  return (
    <>
      <Seo
        title="Developer Community for Coding Tutorials, Blogs, and Resources."
        description="Join The Campus Coders, a vibrant community for developers and tech enthusiasts. Access tutorials, blogs, and projects to enhance your coding skills!"
        imageUrl={cam}
        imageAlt="Campus Coders"
        url="https://www.thecampuscoders.com"
        keywords="Developer Community, Coding Tutorials, Tech Blogs, Programming Resources, Web Development Tutorials, Learn to Code, JavaScript for Beginners, Python Programming, Frontend Development, Backend Development, MERN Stack Developer, Full Stack Development, Software Development, UI/UX Design Tips, Coding Projects for Beginners, Open Source Projects, GitHub Collaboration, Career in Tech, Developer Networking, Coding Challenges"
        canonical="https://www.thecampuscoders.com"
      />
      <div className="container mx-auto px-4 py-8 space-y-8">
        <HeroBanner />
        <FeaturedCategories />
        <UpcomingEvents />
        <LatestBlogs />
        <ProjectSection />
        <TutorialSection />
        <UsefulResources />
        <FreelanceSection />
        <CareerAdvice />
        <TeamMembers />
        <CallToAction />
      </div>
    </>
  );
};

export default Home;
