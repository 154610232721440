import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {AdminSidebar,
  AdminAnalytics,
  ContentModeration,
  UserManagement,
  SystemSettings,
  DashboardOverview} from '../Components/index'

const Admin = () => {
  const location = useLocation();

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <AdminSidebar currentPath={location.pathname} />

      {/* Main content */}
      <div className="flex-1 flex flex-col">
        <main className="flex-1 p-6 overflow-y-auto">
          <Routes>
            <Route path="/dashboard" element={<DashboardOverview />} />
            <Route path="users" element={<UserManagement />} />
            <Route path="content" element={<ContentModeration />} />
            <Route path="analytics" element={<AdminAnalytics />} />
            <Route path="settings" element={<SystemSettings />} />
            {/* Handle 404 for admin routes */}
            <Route path="*" element={<div>Page not found</div>} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default Admin;
