import React, { useState, useEffect } from 'react';
import { Card, Grid, Typography, Box, MenuItem, Select, CircularProgress} from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Analytics = () => {
  const [selectedPeriod, setSelectedPeriod] = useState('monthly');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    // Fetch analytics data from an API
    fetchData();
  }, [selectedPeriod]);

  const fetchData = async () => {
    setLoading(true);
    try {
      // Replace with your API call
      const dummyData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
          {
            label: 'Page Views',
            data: [300, 500, 400, 600, 700, 800],
            borderColor: '#3f51b5',
            backgroundColor: 'rgba(63, 81, 181, 0.5)',
          },
          {
            label: 'User Signups',
            data: [50, 100, 70, 120, 150, 180],
            borderColor: '#f50057',
            backgroundColor: 'rgba(245, 0, 87, 0.5)',
          }
        ]
      };
      setData(dummyData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  return (
    <div className="p-6">
      {/* Period Filter */}
      <Box mb={4} className="flex justify-between items-center">
        <Typography variant="h6" className="font-semibold">Analytics Overview</Typography>
        <Select
          value={selectedPeriod}
          onChange={handlePeriodChange}
          displayEmpty
          className="w-1/4"
        >
          <MenuItem value="monthly">Monthly</MenuItem>
          <MenuItem value="yearly">Yearly</MenuItem>
          <MenuItem value="weekly">Weekly</MenuItem>
        </Select>
      </Box>

      {/* Overview Cards */}
      <Grid container spacing={4}>
        {/* Card 1 */}
        <Grid item xs={12} md={4}>
          <Card className="p-4 shadow-md">
            <Typography variant="h6" className="font-semibold">Total Page Views</Typography>
            <Typography variant="h4" className="mt-2">10,000</Typography>
          </Card>
        </Grid>
        {/* Card 2 */}
        <Grid item xs={12} md={4}>
          <Card className="p-4 shadow-md">
            <Typography variant="h6" className="font-semibold">New Signups</Typography>
            <Typography variant="h4" className="mt-2">500</Typography>
          </Card>
        </Grid>
        {/* Card 3 */}
        <Grid item xs={12} md={4}>
          <Card className="p-4 shadow-md">
            <Typography variant="h6" className="font-semibold">Active Users</Typography>
            <Typography variant="h4" className="mt-2">2,000</Typography>
          </Card>
        </Grid>
      </Grid>

      {/* Chart */}
      <Box mt={4}>
        <Card className="p-4 shadow-md">
          <Typography variant="h6" className="font-semibold mb-4">Page Views & Signups</Typography>
          {loading ? (
            <div className="flex justify-center items-center h-40">
              <CircularProgress />
            </div>
          ) : (
            <Line
              data={data}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return `${context.dataset.label}: ${context.raw}`;
                      }
                    }
                  }
                }
              }}
            />
          )}
        </Card>
      </Box>

      {/* Recent Activity */}
      <Box mt={4}>
        <Card className="p-4 shadow-md">
          <Typography variant="h6" className="font-semibold mb-4">Recent Activity</Typography>
          <ul>
            <li className="mb-2">User Amit Sharma signed up</li>
            <li className="mb-2">User Priya Patel viewed 5 pages</li>
            <li className="mb-2">New comment on blog post "React Hooks"</li>
            <li className="mb-2">User Ravi Kumar liked your post</li>
          </ul>
        </Card>
      </Box>
    </div>
  );
};

export default Analytics;
