import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa';

const events = [
  {
    title: 'React Dev Conference 2024',
    date: 'Sep 15, 2024',
    location: 'San Francisco, CA',
    description: 'Join us for a day of React talks, workshops, and networking.',
    image: 'https://i.ytimg.com/vi/0ckOUBiuxVY/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLCdlVGZj7mf3aZh7DZPoADG9zB5ag',
    link: '/events/react-dev-conference-2024',
  },
  {
    title: 'JavaScript Mastery Workshop',
    date: 'Oct 5, 2024',
    location: 'New York, NY',
    description: 'A comprehensive workshop covering advanced JavaScript topics.',
    image: 'https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=width:705/https://file-uploads.teachablecdn.com/65ed3e0e175d4779a639f49621f65ae1/22f982bc56c541e8b487e9b6c230cab3',
    link: '/events/javascript-mastery-workshop',
  },
  {
    title: 'CSS Grid & Flexbox Summit',
    date: 'Nov 10, 2024',
    location: 'Los Angeles, CA',
    description: 'Deep dive into CSS Grid and Flexbox with industry experts.',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5OqTNuUwY5QyVop9iKyfN12ajob5MCPV3vw&s',
    link: '/events/css-grid-flexbox-summit',
  },
  {
    title: 'Node.js Developer Meetup',
    date: 'Dec 1, 2024',
    location: 'Seattle, WA',
    description: 'Meet fellow Node.js developers and discuss the latest trends.',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwaeulLCu73YDeu4nrIhdxbQ4rUlXp3QXgkQ&s',
    link: '/events/nodejs-developer-meetup',
  },
  {
    title: 'TypeScript in Practice',
    date: 'Jan 20, 2025',
    location: 'Austin, TX',
    description: 'Learn practical applications of TypeScript in real-world projects.',
    image: 'https://media.geeksforgeeks.org/wp-content/uploads/20240704122318/Typescript-Tutorial-copy.webp',
    link: '/events/typescript-in-practice',
  },
];

const UpcomingEvents = () => {
  return (
    <section className="py-10">
      <div className="container">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Upcoming Events</h2>

        <Swiper
          spaceBetween={20}
          slidesPerView={3.5}
          breakpoints={{
            320: {
              slidesPerView: 1.1,
            },
            640: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3.5,
            },
          }}
        >
          {events.map((event, index) => (
            <SwiperSlide key={index}>
              <div className="shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <img src={event.image} alt={event.title} className="w-full h-48 object-cover" />
                <div className="p-5">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">{event.title}</h3>
                  <div className="flex items-center text-gray-500 text-sm mb-2">
                    <FaCalendarAlt className="mr-2 text-blue-500" />
                    {event.date}
                  </div>
                  <div className="flex items-center text-gray-500 text-sm mb-2">
                    <FaMapMarkerAlt className="mr-2 text-blue-500" />
                    {event.location}
                  </div>
                  <p className="text-gray-600 mb-4">{event.description}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}

          {/* Button at the End */}
          <SwiperSlide>
            <div className="flex justify-center items-center h-full">
              <button
                onClick={() => window.location.href = '/all-events'}
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 px-8 rounded-full hover:bg-gradient-to-r hover:from-purple-500 hover:to-blue-500 transition-all duration-300 shadow-lg"
              >
                See All Events
              </button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default UpcomingEvents;
