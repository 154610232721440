import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa';

const projects = [
  {
    title: 'Personal Portfolio',
    description: 'A sleek personal portfolio showcasing my skills and projects.',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyo-wFFR4usHX-6P7Hbgub9TMgtEWhi_SxuA&s',
    github: 'https://github.com/deepakkumar55/personal-portfolio',
    live: 'https://portfolio.example.com',
  },
  {
    title: 'E-commerce Website',
    description: 'An e-commerce platform with features for product listings and shopping cart.',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUsb7TNMjB-QGJGsk_Ceg7sgMFr4O_EniLVA&s',
    github: 'https://github.com/deepakkumar55/e-commerce-website',
    live: 'https://ecommerce.example.com',
  },
  {
    title: 'Blog Platform',
    description: 'A dynamic blog platform with admin functionalities and user engagement.',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzpP8DfpQUmWwAmx8ITwoR7P-wb5QHlGVuhw&s',
    github: 'https://github.com/deepakkumar55/blog-platform',
    live: 'https://blog.example.com',
  },
  {
    title: 'Quiz Application',
    description: 'An interactive quiz application with various question types and results tracking.',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkvR7SL9WZEIB1lCQEnUmJgGcyh_7jn3umag&s',
    github: 'https://github.com/deepakkumar55/quiz-application',
    live: 'https://quiz.example.com',
  },
  {
    title: 'Job Tracker',
    description: 'A job application tracker with features for managing and tracking job applications.',
    image: 'https://cdn.prod.website-files.com/627c8700df0be67c4b1d533c/6494920499c3d7cd150cd384_hcodTLlOqfFskTB9Rdh05MkLo_Tf7AvdW50pX-Q49xfFu7wFGcbgD-Lja6dDNmV4pe5ige6YWEDjg0P8D5NBRYwJMtNvf4Nm_bPNv3aCW-jADeV51OYnmzzAhagC29Y81LJv6fjV285dQxOGG5g6_xw.gif',
    github: 'https://github.com/deepakkumar55/job-tracker',
    live: 'https://jobtracker.example.com',
  },
];

const ProjectsSection = () => {
  return (
    <section className="py-10">
      <div className="container">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Projects</h2>

        <Swiper
          spaceBetween={20}
          slidesPerView={3.5}
          breakpoints={{
            320: {
              slidesPerView: 1.1,
            },
            640: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3.5,
            },
          }}
        >
          {projects.map((project, index) => (
            <SwiperSlide key={index}>
              <div className="shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <img src={project.image} alt={project.title} className="w-full h-48 object-cover" />
                <div className="p-5">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">{project.title}</h3>
                  <p className="text-gray-600 mb-4">{project.description}</p>
                  <div className="flex justify-between items-center text-gray-500 text-sm">
                    <a href={project.github} target="_blank" rel="noopener noreferrer" className="flex items-center hover:text-blue-500">
                      <FaGithub className="mr-2" />
                      GitHub
                    </a>
                    <a href={project.live} target="_blank" rel="noopener noreferrer" className="flex items-center hover:text-blue-500">
                      <FaExternalLinkAlt className="mr-2" />
                      Live
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}

          {/* Button at the End */}
          <SwiperSlide>
            <div className="flex justify-center items-center h-full">
              <button
                onClick={() => window.location.href = '/all-projects'}
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 px-8 rounded-full hover:bg-gradient-to-r hover:from-purple-500 hover:to-blue-500 transition-all duration-300 shadow-lg"
              >
                See All Projects
              </button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default ProjectsSection;
